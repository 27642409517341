/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

export type ManualOrdersListOrderByItem = (typeof ManualOrdersListOrderByItem)[keyof typeof ManualOrdersListOrderByItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ManualOrdersListOrderByItem = {
  '-id': '-id',
  id: 'id'
} as const
