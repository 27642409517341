/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

export type SaleStatusEnum = (typeof SaleStatusEnum)[keyof typeof SaleStatusEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SaleStatusEnum = {
  HIDDEN: 'HIDDEN',
  SALE: 'SALE',
  SOLD_OUT: 'SOLD_OUT'
} as const
