/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

export type SiteAvailableStatusEnum = (typeof SiteAvailableStatusEnum)[keyof typeof SiteAvailableStatusEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SiteAvailableStatusEnum = {
  SITE_AVAILABLE: 'SITE_AVAILABLE',
  SITE_NOT_AVAILABLE: 'SITE_NOT_AVAILABLE',
  SITE_BREAKING_TIME: 'SITE_BREAKING_TIME',
  SITE_ORDER_NOT_AVAILABLE: 'SITE_ORDER_NOT_AVAILABLE'
} as const
