/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

/**
 * * `USER_NOT_FOUND` - 해당 유저가 존재하지 않습니다.
 */
export type CodeCbcEnum = (typeof CodeCbcEnum)[keyof typeof CodeCbcEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CodeCbcEnum = {
  USER_NOT_FOUND: 'USER_NOT_FOUND'
} as const
