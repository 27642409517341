/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

export type ManualOrdersListDeliveryStatusItem =
  (typeof ManualOrdersListDeliveryStatusItem)[keyof typeof ManualOrdersListDeliveryStatusItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ManualOrdersListDeliveryStatusItem = {
  ARRIVED: 'ARRIVED',
  CANCELED: 'CANCELED',
  DELIVERING: 'DELIVERING',
  DONE: 'DONE',
  MOVING: 'MOVING'
} as const
