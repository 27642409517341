/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */
import { useQuery, useInfiniteQuery, useMutation } from '@tanstack/react-query'
import { customFormData } from '../mutator/custom-form-data'
import { customInstance } from '../mutator/custom-instance'
import type {
  AppVersionRes,
  AppVersionReqRequest,
  PatchedAppVersionPartialUpdateReqRequest,
  AppVersionNeedUpdateRes,
  AppVersionsNeedUpdateRetrieveParams,
  PaginatedAdminCompanyResList,
  CompaniesListParams,
  DeviceRes,
  DevicePushTokenReqRequest,
  AdminLoginRes,
  AdminLoginReqRequest,
  AdminLogoutReqRequest,
  PaginatedAdminOrderResList,
  ManualOrdersListParams,
  AdminOrderRes,
  AdminManualOrderCreateReqRequest,
  AdminManualOrderCancelNoteReqRequest,
  PaginatedAdminSiteResList,
  ManualSitesListParams,
  AdminSiteRes,
  AdminNeomPromotionExcelRes,
  AdminNeomPromotionExcelReqRequest,
  NodeRes,
  NodesListParams,
  NodesNodeNumberRetrieveParams,
  OrdersListParams,
  AdminOrderDetailRes,
  CancelOrderRes,
  CancelOrderReqRequest,
  ManualCancelOrderReqRequest,
  AdminOrderDrivingLogRes,
  AdminOrderDrivingLogReqRequest,
  AdminSettlementSummary,
  SettlementsSummaryListParams,
  AdminSettlementExportRes,
  SettlementsSummaryExportRetrieveParams,
  PaginatedAdminShopEventResList,
  ShopEventsListParams,
  AdminShopEventRes,
  AdminShopEventCreateReqRequest,
  AdminShopEventUpdateReqRequest,
  PaginatedAdminShopResList,
  ShopsListParams,
  AdminShopRes,
  AdminShopCreateReqRequest,
  AdminShopUpdateReqRequest,
  AdminShopUpdateImageReqRequest,
  AdminShopUpdateIsOpenReqRequest,
  AdminProductCategoryRes,
  ShopsProductCategoriesListParams,
  AdminProductCategoryCreate400Exception,
  AdminProductCategoryCreateReqRequest,
  AdminProductRes,
  PatchedAdminProductBulkUpdateReqRequest,
  PatchedAdminProductCategoryBulkUpdateReqRequest,
  PaginatedAdminProductResList,
  ShopsProductsListParams,
  AdminProductCreateReqRequest,
  PatchedAdminProductPartialUpdateReqRequest,
  AdminProductUpdateMainReqRequest,
  AdminProductUpdateImageReqRequest,
  AdminProductUpdateSaleStatusReqRequest,
  AdminProductOptionGroupRes,
  AdminProductOptionGroupCreateReqRequest,
  PatchedAdminProductOptionGroupPartialUpdateReqRequest,
  PatchedAdminProductOptionBulkUpdateReqRequest,
  PatchedAdminProductOptionGroupBulkUpdateReqRequest,
  AdminProductOptionRes,
  AdminProductOptionUpdateSaleStatusReqSerializersRequest,
  AdminProductExcelReqRequest,
  SitesListParams,
  AdminSiteRobotRes,
  AdminTokenRefreshRes,
  PaginatedUserResList,
  UsersListParams,
  UserRes,
  AdminUserReqRequest,
  UserDelete404Exception,
  UserResetPassword404Exception,
  PatchedAdminUserResetPasswordReqRequest,
  UserInfoRes,
  AdminUserVerifyRes,
  UserVerify404Exception,
  UsersVerifyRetrieveParams,
  VoucherRes,
  VoucherActivateReqRequest,
  VoucherExcelReqRequest
} from './types'
import type { ErrorType, BodyType } from '../mutator/custom-instance'
import type {
  UseQueryOptions,
  UseInfiniteQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  UseInfiniteQueryResult,
  QueryKey
} from '@tanstack/react-query'

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never

/**
 * 앱 버전 목록을 조회합니다.

권한: 뉴빌리티 어드민
 * @summary 앱 버전 목록 조회
 */
export const appVersionsList = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<AppVersionRes[]>({ url: `/admin/app-versions/`, method: 'get', signal }, options)
}

export const getAppVersionsListQueryKey = () => [`/admin/app-versions/`] as const

export const getAppVersionsListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof appVersionsList>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof appVersionsList>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseInfiniteQueryOptions<Awaited<ReturnType<typeof appVersionsList>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAppVersionsListQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof appVersionsList>>> = ({ signal }) =>
    appVersionsList(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type AppVersionsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof appVersionsList>>>
export type AppVersionsListInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 앱 버전 목록 조회
 */
export const useAppVersionsListInfinite = <
  TData = Awaited<ReturnType<typeof appVersionsList>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof appVersionsList>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAppVersionsListInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getAppVersionsListQueryOptions = <
  TData = Awaited<ReturnType<typeof appVersionsList>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof appVersionsList>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseQueryOptions<Awaited<ReturnType<typeof appVersionsList>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAppVersionsListQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof appVersionsList>>> = ({ signal }) =>
    appVersionsList(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type AppVersionsListQueryResult = NonNullable<Awaited<ReturnType<typeof appVersionsList>>>
export type AppVersionsListQueryError = ErrorType<unknown>

/**
 * @summary 앱 버전 목록 조회
 */
export const useAppVersionsList = <
  TData = Awaited<ReturnType<typeof appVersionsList>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof appVersionsList>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAppVersionsListQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 앱 버전을 생성합니다.

권한: 뉴빌리티 어드민
 * @summary 앱 버전 생성
 */
export const appVersionsCreate = (
  appVersionReqRequest: BodyType<AppVersionReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<AppVersionRes>(
    {
      url: `/admin/app-versions/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: appVersionReqRequest
    },
    options
  )
}

export const getAppVersionsCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof appVersionsCreate>>,
    TError,
    { data: BodyType<AppVersionReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof appVersionsCreate>>,
  TError,
  { data: BodyType<AppVersionReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof appVersionsCreate>>,
    { data: BodyType<AppVersionReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return appVersionsCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type AppVersionsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof appVersionsCreate>>>
export type AppVersionsCreateMutationBody = BodyType<AppVersionReqRequest>
export type AppVersionsCreateMutationError = ErrorType<unknown>

/**
 * @summary 앱 버전 생성
 */
export const useAppVersionsCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof appVersionsCreate>>,
    TError,
    { data: BodyType<AppVersionReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getAppVersionsCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 앱 버전을 부분 수정합니다.

권한: 뉴빌리티 어드민
 * @summary 앱 버전 부분 수정
 */
export const appVersionsPartialUpdate = (
  id: number,
  patchedAppVersionPartialUpdateReqRequest: BodyType<PatchedAppVersionPartialUpdateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<AppVersionRes>(
    {
      url: `/admin/app-versions/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: patchedAppVersionPartialUpdateReqRequest
    },
    options
  )
}

export const getAppVersionsPartialUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof appVersionsPartialUpdate>>,
    TError,
    { id: number; data: BodyType<PatchedAppVersionPartialUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof appVersionsPartialUpdate>>,
  TError,
  { id: number; data: BodyType<PatchedAppVersionPartialUpdateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof appVersionsPartialUpdate>>,
    { id: number; data: BodyType<PatchedAppVersionPartialUpdateReqRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return appVersionsPartialUpdate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type AppVersionsPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof appVersionsPartialUpdate>>>
export type AppVersionsPartialUpdateMutationBody = BodyType<PatchedAppVersionPartialUpdateReqRequest>
export type AppVersionsPartialUpdateMutationError = ErrorType<unknown>

/**
 * @summary 앱 버전 부분 수정
 */
export const useAppVersionsPartialUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof appVersionsPartialUpdate>>,
    TError,
    { id: number; data: BodyType<PatchedAppVersionPartialUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getAppVersionsPartialUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 앱 버전 업데이트 유무를 확인합니다.

권한: 누구나
 * @summary 앱 버전 업데이트 유무 확인
 */
export const appVersionsNeedUpdateRetrieve = (
  params: AppVersionsNeedUpdateRetrieveParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<AppVersionNeedUpdateRes>(
    { url: `/admin/app-versions/need-update/`, method: 'get', params, signal },
    options
  )
}

export const getAppVersionsNeedUpdateRetrieveQueryKey = (params: AppVersionsNeedUpdateRetrieveParams) =>
  [`/admin/app-versions/need-update/`, ...(params ? [params] : [])] as const

export const getAppVersionsNeedUpdateRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof appVersionsNeedUpdateRetrieve>>,
  TError = ErrorType<unknown>
>(
  params: AppVersionsNeedUpdateRetrieveParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof appVersionsNeedUpdateRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryOptions<Awaited<ReturnType<typeof appVersionsNeedUpdateRetrieve>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAppVersionsNeedUpdateRetrieveQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof appVersionsNeedUpdateRetrieve>>> = ({ signal }) =>
    appVersionsNeedUpdateRetrieve(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type AppVersionsNeedUpdateRetrieveInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof appVersionsNeedUpdateRetrieve>>
>
export type AppVersionsNeedUpdateRetrieveInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 앱 버전 업데이트 유무 확인
 */
export const useAppVersionsNeedUpdateRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof appVersionsNeedUpdateRetrieve>>,
  TError = ErrorType<unknown>
>(
  params: AppVersionsNeedUpdateRetrieveParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof appVersionsNeedUpdateRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAppVersionsNeedUpdateRetrieveInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getAppVersionsNeedUpdateRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof appVersionsNeedUpdateRetrieve>>,
  TError = ErrorType<unknown>
>(
  params: AppVersionsNeedUpdateRetrieveParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof appVersionsNeedUpdateRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof appVersionsNeedUpdateRetrieve>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAppVersionsNeedUpdateRetrieveQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof appVersionsNeedUpdateRetrieve>>> = ({ signal }) =>
    appVersionsNeedUpdateRetrieve(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type AppVersionsNeedUpdateRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof appVersionsNeedUpdateRetrieve>>
>
export type AppVersionsNeedUpdateRetrieveQueryError = ErrorType<unknown>

/**
 * @summary 앱 버전 업데이트 유무 확인
 */
export const useAppVersionsNeedUpdateRetrieve = <
  TData = Awaited<ReturnType<typeof appVersionsNeedUpdateRetrieve>>,
  TError = ErrorType<unknown>
>(
  params: AppVersionsNeedUpdateRetrieveParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof appVersionsNeedUpdateRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAppVersionsNeedUpdateRetrieveQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 회사 목록 조회
 * @summary 회사 목록 조회
 */
export const companiesList = (
  params?: CompaniesListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedAdminCompanyResList>(
    { url: `/admin/companies/`, method: 'get', params, signal },
    options
  )
}

export const getCompaniesListQueryKey = (params?: CompaniesListParams) =>
  [`/admin/companies/`, ...(params ? [params] : [])] as const

export const getCompaniesListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof companiesList>>,
  TError = ErrorType<unknown>
>(
  params?: CompaniesListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof companiesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryOptions<Awaited<ReturnType<typeof companiesList>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getCompaniesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof companiesList>>> = ({ signal }) =>
    companiesList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type CompaniesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof companiesList>>>
export type CompaniesListInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 회사 목록 조회
 */
export const useCompaniesListInfinite = <
  TData = Awaited<ReturnType<typeof companiesList>>,
  TError = ErrorType<unknown>
>(
  params?: CompaniesListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof companiesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCompaniesListInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getCompaniesListQueryOptions = <
  TData = Awaited<ReturnType<typeof companiesList>>,
  TError = ErrorType<unknown>
>(
  params?: CompaniesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof companiesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof companiesList>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getCompaniesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof companiesList>>> = ({ signal }) =>
    companiesList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type CompaniesListQueryResult = NonNullable<Awaited<ReturnType<typeof companiesList>>>
export type CompaniesListQueryError = ErrorType<unknown>

/**
 * @summary 회사 목록 조회
 */
export const useCompaniesList = <TData = Awaited<ReturnType<typeof companiesList>>, TError = ErrorType<unknown>>(
  params?: CompaniesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof companiesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCompaniesListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Push Token 등록/수정
 * @summary Push Token 등록/수정
 */
export const devicesPushTokenUpdate = (
  devicePushTokenReqRequest: BodyType<DevicePushTokenReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<DeviceRes>(
    {
      url: `/admin/devices/push-token/`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: devicePushTokenReqRequest
    },
    options
  )
}

export const getDevicesPushTokenUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof devicesPushTokenUpdate>>,
    TError,
    { data: BodyType<DevicePushTokenReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof devicesPushTokenUpdate>>,
  TError,
  { data: BodyType<DevicePushTokenReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof devicesPushTokenUpdate>>,
    { data: BodyType<DevicePushTokenReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return devicesPushTokenUpdate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type DevicesPushTokenUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof devicesPushTokenUpdate>>>
export type DevicesPushTokenUpdateMutationBody = BodyType<DevicePushTokenReqRequest>
export type DevicesPushTokenUpdateMutationError = ErrorType<unknown>

/**
 * @summary Push Token 등록/수정
 */
export const useDevicesPushTokenUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof devicesPushTokenUpdate>>,
    TError,
    { data: BodyType<DevicePushTokenReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getDevicesPushTokenUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 어드민 로그인
 * @summary 어드민 로그인
 */
export const loginCreate = (
  adminLoginReqRequest: BodyType<AdminLoginReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<AdminLoginRes>(
    {
      url: `/admin/login/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: adminLoginReqRequest
    },
    options
  )
}

export const getLoginCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof loginCreate>>,
    TError,
    { data: BodyType<AdminLoginReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof loginCreate>>,
  TError,
  { data: BodyType<AdminLoginReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof loginCreate>>,
    { data: BodyType<AdminLoginReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return loginCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type LoginCreateMutationResult = NonNullable<Awaited<ReturnType<typeof loginCreate>>>
export type LoginCreateMutationBody = BodyType<AdminLoginReqRequest>
export type LoginCreateMutationError = ErrorType<unknown>

/**
 * @summary 어드민 로그인
 */
export const useLoginCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof loginCreate>>,
    TError,
    { data: BodyType<AdminLoginReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getLoginCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 어드민 로그아웃
 * @summary 어드민 로그아웃
 */
export const logoutCreate = (
  adminLogoutReqRequest: BodyType<AdminLogoutReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/admin/logout/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: adminLogoutReqRequest
    },
    options
  )
}

export const getLogoutCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof logoutCreate>>,
    TError,
    { data: BodyType<AdminLogoutReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof logoutCreate>>,
  TError,
  { data: BodyType<AdminLogoutReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof logoutCreate>>,
    { data: BodyType<AdminLogoutReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return logoutCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type LogoutCreateMutationResult = NonNullable<Awaited<ReturnType<typeof logoutCreate>>>
export type LogoutCreateMutationBody = BodyType<AdminLogoutReqRequest>
export type LogoutCreateMutationError = ErrorType<unknown>

/**
 * @summary 어드민 로그아웃
 */
export const useLogoutCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof logoutCreate>>,
    TError,
    { data: BodyType<AdminLogoutReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getLogoutCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 주문 주문 목록 조회
 * @summary 수동 주문 목록 조회
 */
export const manualOrdersList = (
  params?: ManualOrdersListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedAdminOrderResList>(
    { url: `/admin/manual-orders/`, method: 'get', params, signal },
    options
  )
}

export const getManualOrdersListQueryKey = (params?: ManualOrdersListParams) =>
  [`/admin/manual-orders/`, ...(params ? [params] : [])] as const

export const getManualOrdersListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof manualOrdersList>>,
  TError = ErrorType<unknown>
>(
  params?: ManualOrdersListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof manualOrdersList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryOptions<Awaited<ReturnType<typeof manualOrdersList>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getManualOrdersListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof manualOrdersList>>> = ({ signal }) =>
    manualOrdersList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type ManualOrdersListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof manualOrdersList>>>
export type ManualOrdersListInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 수동 주문 목록 조회
 */
export const useManualOrdersListInfinite = <
  TData = Awaited<ReturnType<typeof manualOrdersList>>,
  TError = ErrorType<unknown>
>(
  params?: ManualOrdersListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof manualOrdersList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getManualOrdersListInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getManualOrdersListQueryOptions = <
  TData = Awaited<ReturnType<typeof manualOrdersList>>,
  TError = ErrorType<unknown>
>(
  params?: ManualOrdersListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof manualOrdersList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof manualOrdersList>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getManualOrdersListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof manualOrdersList>>> = ({ signal }) =>
    manualOrdersList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type ManualOrdersListQueryResult = NonNullable<Awaited<ReturnType<typeof manualOrdersList>>>
export type ManualOrdersListQueryError = ErrorType<unknown>

/**
 * @summary 수동 주문 목록 조회
 */
export const useManualOrdersList = <TData = Awaited<ReturnType<typeof manualOrdersList>>, TError = ErrorType<unknown>>(
  params?: ManualOrdersListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof manualOrdersList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getManualOrdersListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 수동 주문 생성
 * @summary 수동 주문 생성
 */
export const manualOrdersCreate = (
  adminManualOrderCreateReqRequest: BodyType<AdminManualOrderCreateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<AdminOrderRes>(
    {
      url: `/admin/manual-orders/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: adminManualOrderCreateReqRequest
    },
    options
  )
}

export const getManualOrdersCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof manualOrdersCreate>>,
    TError,
    { data: BodyType<AdminManualOrderCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof manualOrdersCreate>>,
  TError,
  { data: BodyType<AdminManualOrderCreateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof manualOrdersCreate>>,
    { data: BodyType<AdminManualOrderCreateReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return manualOrdersCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ManualOrdersCreateMutationResult = NonNullable<Awaited<ReturnType<typeof manualOrdersCreate>>>
export type ManualOrdersCreateMutationBody = BodyType<AdminManualOrderCreateReqRequest>
export type ManualOrdersCreateMutationError = ErrorType<unknown>

/**
 * @summary 수동 주문 생성
 */
export const useManualOrdersCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof manualOrdersCreate>>,
    TError,
    { data: BodyType<AdminManualOrderCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getManualOrdersCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 수동 주문 상세
 * @summary 수동 주문 상세
 */
export const manualOrdersRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<AdminOrderRes>({ url: `/admin/manual-orders/${id}/`, method: 'get', signal }, options)
}

export const getManualOrdersRetrieveQueryKey = (id: number) => [`/admin/manual-orders/${id}/`] as const

export const getManualOrdersRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof manualOrdersRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof manualOrdersRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryOptions<Awaited<ReturnType<typeof manualOrdersRetrieve>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getManualOrdersRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof manualOrdersRetrieve>>> = ({ signal }) =>
    manualOrdersRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type ManualOrdersRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof manualOrdersRetrieve>>>
export type ManualOrdersRetrieveInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 수동 주문 상세
 */
export const useManualOrdersRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof manualOrdersRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof manualOrdersRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getManualOrdersRetrieveInfiniteQueryOptions(id, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getManualOrdersRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof manualOrdersRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof manualOrdersRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof manualOrdersRetrieve>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getManualOrdersRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof manualOrdersRetrieve>>> = ({ signal }) =>
    manualOrdersRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type ManualOrdersRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof manualOrdersRetrieve>>>
export type ManualOrdersRetrieveQueryError = ErrorType<unknown>

/**
 * @summary 수동 주문 상세
 */
export const useManualOrdersRetrieve = <
  TData = Awaited<ReturnType<typeof manualOrdersRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof manualOrdersRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getManualOrdersRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 주문 취소 기록
 * @summary 주문 취소 기록
 */
export const manualOrdersCancelUpdate = (
  id: number,
  adminManualOrderCancelNoteReqRequest: BodyType<AdminManualOrderCancelNoteReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<AdminOrderRes>(
    {
      url: `/admin/manual-orders/${id}/cancel/`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: adminManualOrderCancelNoteReqRequest
    },
    options
  )
}

export const getManualOrdersCancelUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof manualOrdersCancelUpdate>>,
    TError,
    { id: number; data: BodyType<AdminManualOrderCancelNoteReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof manualOrdersCancelUpdate>>,
  TError,
  { id: number; data: BodyType<AdminManualOrderCancelNoteReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof manualOrdersCancelUpdate>>,
    { id: number; data: BodyType<AdminManualOrderCancelNoteReqRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return manualOrdersCancelUpdate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ManualOrdersCancelUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof manualOrdersCancelUpdate>>>
export type ManualOrdersCancelUpdateMutationBody = BodyType<AdminManualOrderCancelNoteReqRequest>
export type ManualOrdersCancelUpdateMutationError = ErrorType<unknown>

/**
 * @summary 주문 취소 기록
 */
export const useManualOrdersCancelUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof manualOrdersCancelUpdate>>,
    TError,
    { id: number; data: BodyType<AdminManualOrderCancelNoteReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getManualOrdersCancelUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 수동 주문 사이트 목록 조회
 * @summary 수동 주문 사이트 목록 조회
 */
export const manualSitesList = (
  params?: ManualSitesListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedAdminSiteResList>(
    { url: `/admin/manual-sites/`, method: 'get', params, signal },
    options
  )
}

export const getManualSitesListQueryKey = (params?: ManualSitesListParams) =>
  [`/admin/manual-sites/`, ...(params ? [params] : [])] as const

export const getManualSitesListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof manualSitesList>>,
  TError = ErrorType<unknown>
>(
  params?: ManualSitesListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof manualSitesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryOptions<Awaited<ReturnType<typeof manualSitesList>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getManualSitesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof manualSitesList>>> = ({ signal }) =>
    manualSitesList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type ManualSitesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof manualSitesList>>>
export type ManualSitesListInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 수동 주문 사이트 목록 조회
 */
export const useManualSitesListInfinite = <
  TData = Awaited<ReturnType<typeof manualSitesList>>,
  TError = ErrorType<unknown>
>(
  params?: ManualSitesListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof manualSitesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getManualSitesListInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getManualSitesListQueryOptions = <
  TData = Awaited<ReturnType<typeof manualSitesList>>,
  TError = ErrorType<unknown>
>(
  params?: ManualSitesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof manualSitesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof manualSitesList>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getManualSitesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof manualSitesList>>> = ({ signal }) =>
    manualSitesList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type ManualSitesListQueryResult = NonNullable<Awaited<ReturnType<typeof manualSitesList>>>
export type ManualSitesListQueryError = ErrorType<unknown>

/**
 * @summary 수동 주문 사이트 목록 조회
 */
export const useManualSitesList = <TData = Awaited<ReturnType<typeof manualSitesList>>, TError = ErrorType<unknown>>(
  params?: ManualSitesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof manualSitesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getManualSitesListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 수동 주문 사이트 상세 조회
 * @summary 수동 주문 사이트 상세 조회
 */
export const manualSitesRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<AdminSiteRes>({ url: `/admin/manual-sites/${id}/`, method: 'get', signal }, options)
}

export const getManualSitesRetrieveQueryKey = (id: number) => [`/admin/manual-sites/${id}/`] as const

export const getManualSitesRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof manualSitesRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof manualSitesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryOptions<Awaited<ReturnType<typeof manualSitesRetrieve>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getManualSitesRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof manualSitesRetrieve>>> = ({ signal }) =>
    manualSitesRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type ManualSitesRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof manualSitesRetrieve>>>
export type ManualSitesRetrieveInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 수동 주문 사이트 상세 조회
 */
export const useManualSitesRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof manualSitesRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof manualSitesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getManualSitesRetrieveInfiniteQueryOptions(id, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getManualSitesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof manualSitesRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof manualSitesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof manualSitesRetrieve>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getManualSitesRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof manualSitesRetrieve>>> = ({ signal }) =>
    manualSitesRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type ManualSitesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof manualSitesRetrieve>>>
export type ManualSitesRetrieveQueryError = ErrorType<unknown>

/**
 * @summary 수동 주문 사이트 상세 조회
 */
export const useManualSitesRetrieve = <
  TData = Awaited<ReturnType<typeof manualSitesRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof manualSitesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getManualSitesRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 엑셀로 네옴 프로모션 데이터 등록

권한: 뉴빌리티 어드민
 * @summary 엑셀로 네옴 프로모션 데이터 등록
 */
export const neomPromotionsExcelCreate = (
  adminNeomPromotionExcelReqRequest: BodyType<AdminNeomPromotionExcelReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  const formData = customFormData(adminNeomPromotionExcelReqRequest)
  return customInstance<AdminNeomPromotionExcelRes[]>(
    {
      url: `/admin/neom-promotions/excel/`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData
    },
    options
  )
}

export const getNeomPromotionsExcelCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof neomPromotionsExcelCreate>>,
    TError,
    { data: BodyType<AdminNeomPromotionExcelReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof neomPromotionsExcelCreate>>,
  TError,
  { data: BodyType<AdminNeomPromotionExcelReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof neomPromotionsExcelCreate>>,
    { data: BodyType<AdminNeomPromotionExcelReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return neomPromotionsExcelCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type NeomPromotionsExcelCreateMutationResult = NonNullable<Awaited<ReturnType<typeof neomPromotionsExcelCreate>>>
export type NeomPromotionsExcelCreateMutationBody = BodyType<AdminNeomPromotionExcelReqRequest>
export type NeomPromotionsExcelCreateMutationError = ErrorType<unknown>

/**
 * @summary 엑셀로 네옴 프로모션 데이터 등록
 */
export const useNeomPromotionsExcelCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof neomPromotionsExcelCreate>>,
    TError,
    { data: BodyType<AdminNeomPromotionExcelReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getNeomPromotionsExcelCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 노드 목록 조회
 * @summary 노드 목록 조회
 */
export const nodesList = (
  params: NodesListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<NodeRes[]>({ url: `/admin/nodes/`, method: 'get', params, signal }, options)
}

export const getNodesListQueryKey = (params: NodesListParams) => [`/admin/nodes/`, ...(params ? [params] : [])] as const

export const getNodesListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof nodesList>>,
  TError = ErrorType<unknown>
>(
  params: NodesListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof nodesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryOptions<Awaited<ReturnType<typeof nodesList>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getNodesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof nodesList>>> = ({ signal }) =>
    nodesList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type NodesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof nodesList>>>
export type NodesListInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 노드 목록 조회
 */
export const useNodesListInfinite = <TData = Awaited<ReturnType<typeof nodesList>>, TError = ErrorType<unknown>>(
  params: NodesListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof nodesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNodesListInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getNodesListQueryOptions = <TData = Awaited<ReturnType<typeof nodesList>>, TError = ErrorType<unknown>>(
  params: NodesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof nodesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof nodesList>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getNodesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof nodesList>>> = ({ signal }) =>
    nodesList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type NodesListQueryResult = NonNullable<Awaited<ReturnType<typeof nodesList>>>
export type NodesListQueryError = ErrorType<unknown>

/**
 * @summary 노드 목록 조회
 */
export const useNodesList = <TData = Awaited<ReturnType<typeof nodesList>>, TError = ErrorType<unknown>>(
  params: NodesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof nodesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNodesListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 노드 고유번호로 노드 상세 조회
 * @summary 노드 고유번호로 노드 상세 조회
 */
export const nodesNodeNumberRetrieve = (
  nodeNumber: string,
  params: NodesNodeNumberRetrieveParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<NodeRes>(
    { url: `/admin/nodes/node-number/${nodeNumber}/`, method: 'get', params, signal },
    options
  )
}

export const getNodesNodeNumberRetrieveQueryKey = (nodeNumber: string, params: NodesNodeNumberRetrieveParams) =>
  [`/admin/nodes/node-number/${nodeNumber}/`, ...(params ? [params] : [])] as const

export const getNodesNodeNumberRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof nodesNodeNumberRetrieve>>,
  TError = ErrorType<unknown>
>(
  nodeNumber: string,
  params: NodesNodeNumberRetrieveParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof nodesNodeNumberRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryOptions<Awaited<ReturnType<typeof nodesNodeNumberRetrieve>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getNodesNodeNumberRetrieveQueryKey(nodeNumber, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof nodesNodeNumberRetrieve>>> = ({ signal }) =>
    nodesNodeNumberRetrieve(nodeNumber, params, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!nodeNumber, ...queryOptions }
}

export type NodesNodeNumberRetrieveInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof nodesNodeNumberRetrieve>>
>
export type NodesNodeNumberRetrieveInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 노드 고유번호로 노드 상세 조회
 */
export const useNodesNodeNumberRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof nodesNodeNumberRetrieve>>,
  TError = ErrorType<unknown>
>(
  nodeNumber: string,
  params: NodesNodeNumberRetrieveParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof nodesNodeNumberRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNodesNodeNumberRetrieveInfiniteQueryOptions(nodeNumber, params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getNodesNodeNumberRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof nodesNodeNumberRetrieve>>,
  TError = ErrorType<unknown>
>(
  nodeNumber: string,
  params: NodesNodeNumberRetrieveParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof nodesNodeNumberRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof nodesNodeNumberRetrieve>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getNodesNodeNumberRetrieveQueryKey(nodeNumber, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof nodesNodeNumberRetrieve>>> = ({ signal }) =>
    nodesNodeNumberRetrieve(nodeNumber, params, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!nodeNumber, ...queryOptions }
}

export type NodesNodeNumberRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof nodesNodeNumberRetrieve>>>
export type NodesNodeNumberRetrieveQueryError = ErrorType<unknown>

/**
 * @summary 노드 고유번호로 노드 상세 조회
 */
export const useNodesNodeNumberRetrieve = <
  TData = Awaited<ReturnType<typeof nodesNodeNumberRetrieve>>,
  TError = ErrorType<unknown>
>(
  nodeNumber: string,
  params: NodesNodeNumberRetrieveParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof nodesNodeNumberRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNodesNodeNumberRetrieveQueryOptions(nodeNumber, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 주문 목록 조회
 * @summary 주문 목록 조회
 */
export const ordersList = (
  params?: OrdersListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedAdminOrderResList>({ url: `/admin/orders/`, method: 'get', params, signal }, options)
}

export const getOrdersListQueryKey = (params?: OrdersListParams) =>
  [`/admin/orders/`, ...(params ? [params] : [])] as const

export const getOrdersListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof ordersList>>,
  TError = ErrorType<unknown>
>(
  params?: OrdersListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof ordersList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryOptions<Awaited<ReturnType<typeof ordersList>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOrdersListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof ordersList>>> = ({ signal }) =>
    ordersList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type OrdersListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof ordersList>>>
export type OrdersListInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 주문 목록 조회
 */
export const useOrdersListInfinite = <TData = Awaited<ReturnType<typeof ordersList>>, TError = ErrorType<unknown>>(
  params?: OrdersListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof ordersList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getOrdersListInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getOrdersListQueryOptions = <TData = Awaited<ReturnType<typeof ordersList>>, TError = ErrorType<unknown>>(
  params?: OrdersListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof ordersList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof ordersList>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOrdersListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof ordersList>>> = ({ signal }) =>
    ordersList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type OrdersListQueryResult = NonNullable<Awaited<ReturnType<typeof ordersList>>>
export type OrdersListQueryError = ErrorType<unknown>

/**
 * @summary 주문 목록 조회
 */
export const useOrdersList = <TData = Awaited<ReturnType<typeof ordersList>>, TError = ErrorType<unknown>>(
  params?: OrdersListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof ordersList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getOrdersListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 주문 상세 조회
 * @summary 주문 상세 조회
 */
export const ordersRetrieve = (id: number, options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<AdminOrderDetailRes>({ url: `/admin/orders/${id}/`, method: 'get', signal }, options)
}

export const getOrdersRetrieveQueryKey = (id: number) => [`/admin/orders/${id}/`] as const

export const getOrdersRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof ordersRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof ordersRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryOptions<Awaited<ReturnType<typeof ordersRetrieve>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOrdersRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof ordersRetrieve>>> = ({ signal }) =>
    ordersRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type OrdersRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof ordersRetrieve>>>
export type OrdersRetrieveInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 주문 상세 조회
 */
export const useOrdersRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof ordersRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof ordersRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getOrdersRetrieveInfiniteQueryOptions(id, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getOrdersRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof ordersRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof ordersRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof ordersRetrieve>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOrdersRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof ordersRetrieve>>> = ({ signal }) =>
    ordersRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type OrdersRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof ordersRetrieve>>>
export type OrdersRetrieveQueryError = ErrorType<unknown>

/**
 * @summary 주문 상세 조회
 */
export const useOrdersRetrieve = <TData = Awaited<ReturnType<typeof ordersRetrieve>>, TError = ErrorType<unknown>>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof ordersRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getOrdersRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 주문 접수
 * @summary 주문 접수
 */
export const ordersAcceptUpdate = (id: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<string>({ url: `/admin/orders/${id}/accept/`, method: 'put' }, options)
}

export const getOrdersAcceptUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof ordersAcceptUpdate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof ordersAcceptUpdate>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof ordersAcceptUpdate>>, { id: number }> = (props) => {
    const { id } = props ?? {}

    return ordersAcceptUpdate(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type OrdersAcceptUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof ordersAcceptUpdate>>>

export type OrdersAcceptUpdateMutationError = ErrorType<unknown>

/**
 * @summary 주문 접수
 */
export const useOrdersAcceptUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof ordersAcceptUpdate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getOrdersAcceptUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 로봇 뚜껑 열기
 * @summary 로봇 뚜껑 열기
 */
export const ordersOpenCoverUpdate = (id: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<string>({ url: `/admin/orders/${id}/open-cover/`, method: 'put' }, options)
}

export const getOrdersOpenCoverUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof ordersOpenCoverUpdate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof ordersOpenCoverUpdate>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof ordersOpenCoverUpdate>>, { id: number }> = (props) => {
    const { id } = props ?? {}

    return ordersOpenCoverUpdate(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type OrdersOpenCoverUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof ordersOpenCoverUpdate>>>

export type OrdersOpenCoverUpdateMutationError = ErrorType<unknown>

/**
 * @summary 로봇 뚜껑 열기
 */
export const useOrdersOpenCoverUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof ordersOpenCoverUpdate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getOrdersOpenCoverUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 주문 배달 시작
 * @summary 주문 배달 시작
 */
export const ordersStartDeliveryUpdate = (id: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<string>({ url: `/admin/orders/${id}/start-delivery/`, method: 'put' }, options)
}

export const getOrdersStartDeliveryUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof ordersStartDeliveryUpdate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof ordersStartDeliveryUpdate>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof ordersStartDeliveryUpdate>>, { id: number }> = (
    props
  ) => {
    const { id } = props ?? {}

    return ordersStartDeliveryUpdate(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type OrdersStartDeliveryUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof ordersStartDeliveryUpdate>>>

export type OrdersStartDeliveryUpdateMutationError = ErrorType<unknown>

/**
 * @summary 주문 배달 시작
 */
export const useOrdersStartDeliveryUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof ordersStartDeliveryUpdate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getOrdersStartDeliveryUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 주문 취소
 * @summary 주문 취소
 */
export const ordersCancelCreate = (
  cancelOrderReqRequest: BodyType<CancelOrderReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CancelOrderRes>(
    {
      url: `/admin/orders/cancel/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: cancelOrderReqRequest
    },
    options
  )
}

export const getOrdersCancelCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ordersCancelCreate>>,
    TError,
    { data: BodyType<CancelOrderReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof ordersCancelCreate>>,
  TError,
  { data: BodyType<CancelOrderReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof ordersCancelCreate>>,
    { data: BodyType<CancelOrderReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return ordersCancelCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type OrdersCancelCreateMutationResult = NonNullable<Awaited<ReturnType<typeof ordersCancelCreate>>>
export type OrdersCancelCreateMutationBody = BodyType<CancelOrderReqRequest>
export type OrdersCancelCreateMutationError = ErrorType<unknown>

/**
 * @summary 주문 취소
 */
export const useOrdersCancelCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ordersCancelCreate>>,
    TError,
    { data: BodyType<CancelOrderReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getOrdersCancelCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 수동 주문 취소
 * @summary 수동 주문 취소
 */
export const ordersCancelManualCreate = (
  manualCancelOrderReqRequest: BodyType<ManualCancelOrderReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CancelOrderRes>(
    {
      url: `/admin/orders/cancel-manual/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: manualCancelOrderReqRequest
    },
    options
  )
}

export const getOrdersCancelManualCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ordersCancelManualCreate>>,
    TError,
    { data: BodyType<ManualCancelOrderReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof ordersCancelManualCreate>>,
  TError,
  { data: BodyType<ManualCancelOrderReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof ordersCancelManualCreate>>,
    { data: BodyType<ManualCancelOrderReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return ordersCancelManualCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type OrdersCancelManualCreateMutationResult = NonNullable<Awaited<ReturnType<typeof ordersCancelManualCreate>>>
export type OrdersCancelManualCreateMutationBody = BodyType<ManualCancelOrderReqRequest>
export type OrdersCancelManualCreateMutationError = ErrorType<unknown>

/**
 * @summary 수동 주문 취소
 */
export const useOrdersCancelManualCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ordersCancelManualCreate>>,
    TError,
    { data: BodyType<ManualCancelOrderReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getOrdersCancelManualCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 주문 주행 기록 확인
 * @summary 주문 주행 기록 획인
 */
export const ordersDrivingLogCreate = (
  adminOrderDrivingLogReqRequest: BodyType<AdminOrderDrivingLogReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<AdminOrderDrivingLogRes>(
    {
      url: `/admin/orders/driving-log/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: adminOrderDrivingLogReqRequest
    },
    options
  )
}

export const getOrdersDrivingLogCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ordersDrivingLogCreate>>,
    TError,
    { data: BodyType<AdminOrderDrivingLogReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof ordersDrivingLogCreate>>,
  TError,
  { data: BodyType<AdminOrderDrivingLogReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof ordersDrivingLogCreate>>,
    { data: BodyType<AdminOrderDrivingLogReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return ordersDrivingLogCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type OrdersDrivingLogCreateMutationResult = NonNullable<Awaited<ReturnType<typeof ordersDrivingLogCreate>>>
export type OrdersDrivingLogCreateMutationBody = BodyType<AdminOrderDrivingLogReqRequest>
export type OrdersDrivingLogCreateMutationError = ErrorType<unknown>

/**
 * @summary 주문 주행 기록 획인
 */
export const useOrdersDrivingLogCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ordersDrivingLogCreate>>,
    TError,
    { data: BodyType<AdminOrderDrivingLogReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getOrdersDrivingLogCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 매점 정산 요약
 * @summary 매점 정산 요약
 */
export const settlementsSummaryList = (
  params: SettlementsSummaryListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<AdminSettlementSummary[]>(
    { url: `/admin/settlements/summary/`, method: 'get', params, signal },
    options
  )
}

export const getSettlementsSummaryListQueryKey = (params: SettlementsSummaryListParams) =>
  [`/admin/settlements/summary/`, ...(params ? [params] : [])] as const

export const getSettlementsSummaryListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof settlementsSummaryList>>,
  TError = ErrorType<unknown>
>(
  params: SettlementsSummaryListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof settlementsSummaryList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryOptions<Awaited<ReturnType<typeof settlementsSummaryList>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSettlementsSummaryListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof settlementsSummaryList>>> = ({ signal }) =>
    settlementsSummaryList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type SettlementsSummaryListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof settlementsSummaryList>>>
export type SettlementsSummaryListInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 매점 정산 요약
 */
export const useSettlementsSummaryListInfinite = <
  TData = Awaited<ReturnType<typeof settlementsSummaryList>>,
  TError = ErrorType<unknown>
>(
  params: SettlementsSummaryListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof settlementsSummaryList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSettlementsSummaryListInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getSettlementsSummaryListQueryOptions = <
  TData = Awaited<ReturnType<typeof settlementsSummaryList>>,
  TError = ErrorType<unknown>
>(
  params: SettlementsSummaryListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof settlementsSummaryList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof settlementsSummaryList>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSettlementsSummaryListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof settlementsSummaryList>>> = ({ signal }) =>
    settlementsSummaryList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type SettlementsSummaryListQueryResult = NonNullable<Awaited<ReturnType<typeof settlementsSummaryList>>>
export type SettlementsSummaryListQueryError = ErrorType<unknown>

/**
 * @summary 매점 정산 요약
 */
export const useSettlementsSummaryList = <
  TData = Awaited<ReturnType<typeof settlementsSummaryList>>,
  TError = ErrorType<unknown>
>(
  params: SettlementsSummaryListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof settlementsSummaryList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSettlementsSummaryListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 매점 정산 엑셀파일 생성
 * @summary 매점 정산 엑셀파일 생성
 */
export const settlementsSummaryExportRetrieve = (
  params: SettlementsSummaryExportRetrieveParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<AdminSettlementExportRes>(
    { url: `/admin/settlements/summary/export/`, method: 'get', params, signal },
    options
  )
}

export const getSettlementsSummaryExportRetrieveQueryKey = (params: SettlementsSummaryExportRetrieveParams) =>
  [`/admin/settlements/summary/export/`, ...(params ? [params] : [])] as const

export const getSettlementsSummaryExportRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof settlementsSummaryExportRetrieve>>,
  TError = ErrorType<unknown>
>(
  params: SettlementsSummaryExportRetrieveParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof settlementsSummaryExportRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryOptions<Awaited<ReturnType<typeof settlementsSummaryExportRetrieve>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSettlementsSummaryExportRetrieveQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof settlementsSummaryExportRetrieve>>> = ({ signal }) =>
    settlementsSummaryExportRetrieve(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type SettlementsSummaryExportRetrieveInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof settlementsSummaryExportRetrieve>>
>
export type SettlementsSummaryExportRetrieveInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 매점 정산 엑셀파일 생성
 */
export const useSettlementsSummaryExportRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof settlementsSummaryExportRetrieve>>,
  TError = ErrorType<unknown>
>(
  params: SettlementsSummaryExportRetrieveParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof settlementsSummaryExportRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSettlementsSummaryExportRetrieveInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getSettlementsSummaryExportRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof settlementsSummaryExportRetrieve>>,
  TError = ErrorType<unknown>
>(
  params: SettlementsSummaryExportRetrieveParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof settlementsSummaryExportRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof settlementsSummaryExportRetrieve>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSettlementsSummaryExportRetrieveQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof settlementsSummaryExportRetrieve>>> = ({ signal }) =>
    settlementsSummaryExportRetrieve(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type SettlementsSummaryExportRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof settlementsSummaryExportRetrieve>>
>
export type SettlementsSummaryExportRetrieveQueryError = ErrorType<unknown>

/**
 * @summary 매점 정산 엑셀파일 생성
 */
export const useSettlementsSummaryExportRetrieve = <
  TData = Awaited<ReturnType<typeof settlementsSummaryExportRetrieve>>,
  TError = ErrorType<unknown>
>(
  params: SettlementsSummaryExportRetrieveParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof settlementsSummaryExportRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSettlementsSummaryExportRetrieveQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 이벤트 목록 조회
 * @summary 이벤트 목록 조회
 */
export const shopEventsList = (
  params?: ShopEventsListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedAdminShopEventResList>(
    { url: `/admin/shop-events/`, method: 'get', params, signal },
    options
  )
}

export const getShopEventsListQueryKey = (params?: ShopEventsListParams) =>
  [`/admin/shop-events/`, ...(params ? [params] : [])] as const

export const getShopEventsListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof shopEventsList>>,
  TError = ErrorType<unknown>
>(
  params?: ShopEventsListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof shopEventsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryOptions<Awaited<ReturnType<typeof shopEventsList>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getShopEventsListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof shopEventsList>>> = ({ signal }) =>
    shopEventsList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type ShopEventsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof shopEventsList>>>
export type ShopEventsListInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 이벤트 목록 조회
 */
export const useShopEventsListInfinite = <
  TData = Awaited<ReturnType<typeof shopEventsList>>,
  TError = ErrorType<unknown>
>(
  params?: ShopEventsListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof shopEventsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getShopEventsListInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getShopEventsListQueryOptions = <
  TData = Awaited<ReturnType<typeof shopEventsList>>,
  TError = ErrorType<unknown>
>(
  params?: ShopEventsListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof shopEventsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof shopEventsList>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getShopEventsListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof shopEventsList>>> = ({ signal }) =>
    shopEventsList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type ShopEventsListQueryResult = NonNullable<Awaited<ReturnType<typeof shopEventsList>>>
export type ShopEventsListQueryError = ErrorType<unknown>

/**
 * @summary 이벤트 목록 조회
 */
export const useShopEventsList = <TData = Awaited<ReturnType<typeof shopEventsList>>, TError = ErrorType<unknown>>(
  params?: ShopEventsListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof shopEventsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getShopEventsListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 이벤트 생성
 * @summary 이벤트 생성
 */
export const shopEventsCreate = (
  adminShopEventCreateReqRequest: BodyType<AdminShopEventCreateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  const formData = customFormData(adminShopEventCreateReqRequest)
  return customInstance<AdminShopEventRes>(
    { url: `/admin/shop-events/`, method: 'post', headers: { 'Content-Type': 'multipart/form-data' }, data: formData },
    options
  )
}

export const getShopEventsCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopEventsCreate>>,
    TError,
    { data: BodyType<AdminShopEventCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof shopEventsCreate>>,
  TError,
  { data: BodyType<AdminShopEventCreateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof shopEventsCreate>>,
    { data: BodyType<AdminShopEventCreateReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return shopEventsCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ShopEventsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof shopEventsCreate>>>
export type ShopEventsCreateMutationBody = BodyType<AdminShopEventCreateReqRequest>
export type ShopEventsCreateMutationError = ErrorType<unknown>

/**
 * @summary 이벤트 생성
 */
export const useShopEventsCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopEventsCreate>>,
    TError,
    { data: BodyType<AdminShopEventCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getShopEventsCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 이벤트 상세 조회
 * @summary 이벤트 상세 조회
 */
export const shopEventsRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<AdminShopEventRes>({ url: `/admin/shop-events/${id}/`, method: 'get', signal }, options)
}

export const getShopEventsRetrieveQueryKey = (id: number) => [`/admin/shop-events/${id}/`] as const

export const getShopEventsRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof shopEventsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof shopEventsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryOptions<Awaited<ReturnType<typeof shopEventsRetrieve>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getShopEventsRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof shopEventsRetrieve>>> = ({ signal }) =>
    shopEventsRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type ShopEventsRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof shopEventsRetrieve>>>
export type ShopEventsRetrieveInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 이벤트 상세 조회
 */
export const useShopEventsRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof shopEventsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof shopEventsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getShopEventsRetrieveInfiniteQueryOptions(id, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getShopEventsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof shopEventsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof shopEventsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof shopEventsRetrieve>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getShopEventsRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof shopEventsRetrieve>>> = ({ signal }) =>
    shopEventsRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type ShopEventsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof shopEventsRetrieve>>>
export type ShopEventsRetrieveQueryError = ErrorType<unknown>

/**
 * @summary 이벤트 상세 조회
 */
export const useShopEventsRetrieve = <
  TData = Awaited<ReturnType<typeof shopEventsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof shopEventsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getShopEventsRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 이벤트 정보 수정
 * @summary 이벤트 정보 수정
 */
export const shopEventsUpdate = (
  id: number,
  adminShopEventUpdateReqRequest: BodyType<AdminShopEventUpdateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  const formData = customFormData(adminShopEventUpdateReqRequest)
  return customInstance<AdminShopEventRes>(
    {
      url: `/admin/shop-events/${id}/`,
      method: 'put',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData
    },
    options
  )
}

export const getShopEventsUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopEventsUpdate>>,
    TError,
    { id: number; data: BodyType<AdminShopEventUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof shopEventsUpdate>>,
  TError,
  { id: number; data: BodyType<AdminShopEventUpdateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof shopEventsUpdate>>,
    { id: number; data: BodyType<AdminShopEventUpdateReqRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return shopEventsUpdate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ShopEventsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof shopEventsUpdate>>>
export type ShopEventsUpdateMutationBody = BodyType<AdminShopEventUpdateReqRequest>
export type ShopEventsUpdateMutationError = ErrorType<unknown>

/**
 * @summary 이벤트 정보 수정
 */
export const useShopEventsUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopEventsUpdate>>,
    TError,
    { id: number; data: BodyType<AdminShopEventUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getShopEventsUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 샵 목록 조회
 * @summary 샵 목록 조회
 */
export const shopsList = (
  params?: ShopsListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedAdminShopResList>({ url: `/admin/shops/`, method: 'get', params, signal }, options)
}

export const getShopsListQueryKey = (params?: ShopsListParams) =>
  [`/admin/shops/`, ...(params ? [params] : [])] as const

export const getShopsListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof shopsList>>,
  TError = ErrorType<unknown>
>(
  params?: ShopsListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof shopsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryOptions<Awaited<ReturnType<typeof shopsList>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getShopsListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof shopsList>>> = ({ signal }) =>
    shopsList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type ShopsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof shopsList>>>
export type ShopsListInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 샵 목록 조회
 */
export const useShopsListInfinite = <TData = Awaited<ReturnType<typeof shopsList>>, TError = ErrorType<unknown>>(
  params?: ShopsListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof shopsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getShopsListInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getShopsListQueryOptions = <TData = Awaited<ReturnType<typeof shopsList>>, TError = ErrorType<unknown>>(
  params?: ShopsListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof shopsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof shopsList>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getShopsListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof shopsList>>> = ({ signal }) =>
    shopsList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type ShopsListQueryResult = NonNullable<Awaited<ReturnType<typeof shopsList>>>
export type ShopsListQueryError = ErrorType<unknown>

/**
 * @summary 샵 목록 조회
 */
export const useShopsList = <TData = Awaited<ReturnType<typeof shopsList>>, TError = ErrorType<unknown>>(
  params?: ShopsListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof shopsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getShopsListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 샵 생성
 * @summary 샵 생성
 */
export const shopsCreate = (
  adminShopCreateReqRequest: BodyType<AdminShopCreateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  const formData = customFormData(adminShopCreateReqRequest)
  return customInstance<AdminShopRes>(
    { url: `/admin/shops/`, method: 'post', headers: { 'Content-Type': 'multipart/form-data' }, data: formData },
    options
  )
}

export const getShopsCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsCreate>>,
    TError,
    { data: BodyType<AdminShopCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof shopsCreate>>,
  TError,
  { data: BodyType<AdminShopCreateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof shopsCreate>>,
    { data: BodyType<AdminShopCreateReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return shopsCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ShopsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof shopsCreate>>>
export type ShopsCreateMutationBody = BodyType<AdminShopCreateReqRequest>
export type ShopsCreateMutationError = ErrorType<unknown>

/**
 * @summary 샵 생성
 */
export const useShopsCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsCreate>>,
    TError,
    { data: BodyType<AdminShopCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getShopsCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 샵 상세 조회
 * @summary 샵 상세 조회
 */
export const shopsRetrieve = (id: number, options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<AdminShopRes>({ url: `/admin/shops/${id}/`, method: 'get', signal }, options)
}

export const getShopsRetrieveQueryKey = (id: number) => [`/admin/shops/${id}/`] as const

export const getShopsRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof shopsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof shopsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryOptions<Awaited<ReturnType<typeof shopsRetrieve>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getShopsRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof shopsRetrieve>>> = ({ signal }) =>
    shopsRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type ShopsRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof shopsRetrieve>>>
export type ShopsRetrieveInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 샵 상세 조회
 */
export const useShopsRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof shopsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof shopsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getShopsRetrieveInfiniteQueryOptions(id, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getShopsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof shopsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof shopsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof shopsRetrieve>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getShopsRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof shopsRetrieve>>> = ({ signal }) =>
    shopsRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type ShopsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof shopsRetrieve>>>
export type ShopsRetrieveQueryError = ErrorType<unknown>

/**
 * @summary 샵 상세 조회
 */
export const useShopsRetrieve = <TData = Awaited<ReturnType<typeof shopsRetrieve>>, TError = ErrorType<unknown>>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof shopsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getShopsRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 사이트 정보 수정
 * @summary 사이트 정보 수정
 */
export const shopsUpdate = (
  id: number,
  adminShopUpdateReqRequest: BodyType<AdminShopUpdateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  const formData = customFormData(adminShopUpdateReqRequest)
  return customInstance<AdminShopRes>(
    { url: `/admin/shops/${id}/`, method: 'put', headers: { 'Content-Type': 'multipart/form-data' }, data: formData },
    options
  )
}

export const getShopsUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsUpdate>>,
    TError,
    { id: number; data: BodyType<AdminShopUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof shopsUpdate>>,
  TError,
  { id: number; data: BodyType<AdminShopUpdateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof shopsUpdate>>,
    { id: number; data: BodyType<AdminShopUpdateReqRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return shopsUpdate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ShopsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof shopsUpdate>>>
export type ShopsUpdateMutationBody = BodyType<AdminShopUpdateReqRequest>
export type ShopsUpdateMutationError = ErrorType<unknown>

/**
 * @summary 사이트 정보 수정
 */
export const useShopsUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsUpdate>>,
    TError,
    { id: number; data: BodyType<AdminShopUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getShopsUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 샵 대표 이미지 등록
 * @summary 샵 대표 이미지 등록
 */
export const shopsMainImageUpdate = (
  id: number,
  adminShopUpdateImageReqRequest: BodyType<AdminShopUpdateImageReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  const formData = customFormData(adminShopUpdateImageReqRequest)
  return customInstance<AdminShopRes>(
    {
      url: `/admin/shops/${id}/main-image/`,
      method: 'put',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData
    },
    options
  )
}

export const getShopsMainImageUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsMainImageUpdate>>,
    TError,
    { id: number; data: BodyType<AdminShopUpdateImageReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof shopsMainImageUpdate>>,
  TError,
  { id: number; data: BodyType<AdminShopUpdateImageReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof shopsMainImageUpdate>>,
    { id: number; data: BodyType<AdminShopUpdateImageReqRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return shopsMainImageUpdate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ShopsMainImageUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof shopsMainImageUpdate>>>
export type ShopsMainImageUpdateMutationBody = BodyType<AdminShopUpdateImageReqRequest>
export type ShopsMainImageUpdateMutationError = ErrorType<unknown>

/**
 * @summary 샵 대표 이미지 등록
 */
export const useShopsMainImageUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsMainImageUpdate>>,
    TError,
    { id: number; data: BodyType<AdminShopUpdateImageReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getShopsMainImageUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 샵 열림 닫힘 설정
 * @summary 샵 열림 닫힘 설정
 */
export const shopsOpenUpdate = (
  id: number,
  adminShopUpdateIsOpenReqRequest: BodyType<AdminShopUpdateIsOpenReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<AdminShopRes>(
    {
      url: `/admin/shops/${id}/open/`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: adminShopUpdateIsOpenReqRequest
    },
    options
  )
}

export const getShopsOpenUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsOpenUpdate>>,
    TError,
    { id: number; data: BodyType<AdminShopUpdateIsOpenReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof shopsOpenUpdate>>,
  TError,
  { id: number; data: BodyType<AdminShopUpdateIsOpenReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof shopsOpenUpdate>>,
    { id: number; data: BodyType<AdminShopUpdateIsOpenReqRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return shopsOpenUpdate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ShopsOpenUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof shopsOpenUpdate>>>
export type ShopsOpenUpdateMutationBody = BodyType<AdminShopUpdateIsOpenReqRequest>
export type ShopsOpenUpdateMutationError = ErrorType<unknown>

/**
 * @summary 샵 열림 닫힘 설정
 */
export const useShopsOpenUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsOpenUpdate>>,
    TError,
    { id: number; data: BodyType<AdminShopUpdateIsOpenReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getShopsOpenUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 상품 카테고리 목록 조회
 * @summary 상품 카테고리 목록 조회
 */
export const shopsProductCategoriesList = (
  shopPk: number,
  params?: ShopsProductCategoriesListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<AdminProductCategoryRes[]>(
    { url: `/admin/shops/${shopPk}/product-categories/`, method: 'get', params, signal },
    options
  )
}

export const getShopsProductCategoriesListQueryKey = (shopPk: number, params?: ShopsProductCategoriesListParams) =>
  [`/admin/shops/${shopPk}/product-categories/`, ...(params ? [params] : [])] as const

export const getShopsProductCategoriesListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof shopsProductCategoriesList>>,
  TError = ErrorType<unknown>
>(
  shopPk: number,
  params?: ShopsProductCategoriesListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof shopsProductCategoriesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryOptions<Awaited<ReturnType<typeof shopsProductCategoriesList>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getShopsProductCategoriesListQueryKey(shopPk, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof shopsProductCategoriesList>>> = ({ signal }) =>
    shopsProductCategoriesList(shopPk, params, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!shopPk, ...queryOptions }
}

export type ShopsProductCategoriesListInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof shopsProductCategoriesList>>
>
export type ShopsProductCategoriesListInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 상품 카테고리 목록 조회
 */
export const useShopsProductCategoriesListInfinite = <
  TData = Awaited<ReturnType<typeof shopsProductCategoriesList>>,
  TError = ErrorType<unknown>
>(
  shopPk: number,
  params?: ShopsProductCategoriesListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof shopsProductCategoriesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getShopsProductCategoriesListInfiniteQueryOptions(shopPk, params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getShopsProductCategoriesListQueryOptions = <
  TData = Awaited<ReturnType<typeof shopsProductCategoriesList>>,
  TError = ErrorType<unknown>
>(
  shopPk: number,
  params?: ShopsProductCategoriesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof shopsProductCategoriesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof shopsProductCategoriesList>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getShopsProductCategoriesListQueryKey(shopPk, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof shopsProductCategoriesList>>> = ({ signal }) =>
    shopsProductCategoriesList(shopPk, params, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!shopPk, ...queryOptions }
}

export type ShopsProductCategoriesListQueryResult = NonNullable<Awaited<ReturnType<typeof shopsProductCategoriesList>>>
export type ShopsProductCategoriesListQueryError = ErrorType<unknown>

/**
 * @summary 상품 카테고리 목록 조회
 */
export const useShopsProductCategoriesList = <
  TData = Awaited<ReturnType<typeof shopsProductCategoriesList>>,
  TError = ErrorType<unknown>
>(
  shopPk: number,
  params?: ShopsProductCategoriesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof shopsProductCategoriesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getShopsProductCategoriesListQueryOptions(shopPk, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 상품 카테고리 추가
 * @summary 상품 카테고리 추가
 */
export const shopsProductCategoriesCreate = (
  shopPk: number,
  adminProductCategoryCreateReqRequest: BodyType<AdminProductCategoryCreateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<AdminProductCategoryRes>(
    {
      url: `/admin/shops/${shopPk}/product-categories/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: adminProductCategoryCreateReqRequest
    },
    options
  )
}

export const getShopsProductCategoriesCreateMutationOptions = <
  TError = ErrorType<AdminProductCategoryCreate400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsProductCategoriesCreate>>,
    TError,
    { shopPk: number; data: BodyType<AdminProductCategoryCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof shopsProductCategoriesCreate>>,
  TError,
  { shopPk: number; data: BodyType<AdminProductCategoryCreateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof shopsProductCategoriesCreate>>,
    { shopPk: number; data: BodyType<AdminProductCategoryCreateReqRequest> }
  > = (props) => {
    const { shopPk, data } = props ?? {}

    return shopsProductCategoriesCreate(shopPk, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ShopsProductCategoriesCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof shopsProductCategoriesCreate>>
>
export type ShopsProductCategoriesCreateMutationBody = BodyType<AdminProductCategoryCreateReqRequest>
export type ShopsProductCategoriesCreateMutationError = ErrorType<AdminProductCategoryCreate400Exception>

/**
 * @summary 상품 카테고리 추가
 */
export const useShopsProductCategoriesCreate = <
  TError = ErrorType<AdminProductCategoryCreate400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsProductCategoriesCreate>>,
    TError,
    { shopPk: number; data: BodyType<AdminProductCategoryCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getShopsProductCategoriesCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 상품 카테고리 상세 조회
 * @summary 상품 카테고리 상세 조회
 */
export const shopsProductCategoriesRetrieve = (
  shopPk: number,
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<AdminProductCategoryRes>(
    { url: `/admin/shops/${shopPk}/product-categories/${id}/`, method: 'get', signal },
    options
  )
}

export const getShopsProductCategoriesRetrieveQueryKey = (shopPk: number, id: number) =>
  [`/admin/shops/${shopPk}/product-categories/${id}/`] as const

export const getShopsProductCategoriesRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof shopsProductCategoriesRetrieve>>,
  TError = ErrorType<unknown>
>(
  shopPk: number,
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof shopsProductCategoriesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryOptions<Awaited<ReturnType<typeof shopsProductCategoriesRetrieve>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getShopsProductCategoriesRetrieveQueryKey(shopPk, id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof shopsProductCategoriesRetrieve>>> = ({ signal }) =>
    shopsProductCategoriesRetrieve(shopPk, id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!(shopPk && id), ...queryOptions }
}

export type ShopsProductCategoriesRetrieveInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof shopsProductCategoriesRetrieve>>
>
export type ShopsProductCategoriesRetrieveInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 상품 카테고리 상세 조회
 */
export const useShopsProductCategoriesRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof shopsProductCategoriesRetrieve>>,
  TError = ErrorType<unknown>
>(
  shopPk: number,
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof shopsProductCategoriesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getShopsProductCategoriesRetrieveInfiniteQueryOptions(shopPk, id, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getShopsProductCategoriesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof shopsProductCategoriesRetrieve>>,
  TError = ErrorType<unknown>
>(
  shopPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof shopsProductCategoriesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof shopsProductCategoriesRetrieve>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getShopsProductCategoriesRetrieveQueryKey(shopPk, id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof shopsProductCategoriesRetrieve>>> = ({ signal }) =>
    shopsProductCategoriesRetrieve(shopPk, id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!(shopPk && id), ...queryOptions }
}

export type ShopsProductCategoriesRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof shopsProductCategoriesRetrieve>>
>
export type ShopsProductCategoriesRetrieveQueryError = ErrorType<unknown>

/**
 * @summary 상품 카테고리 상세 조회
 */
export const useShopsProductCategoriesRetrieve = <
  TData = Awaited<ReturnType<typeof shopsProductCategoriesRetrieve>>,
  TError = ErrorType<unknown>
>(
  shopPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof shopsProductCategoriesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getShopsProductCategoriesRetrieveQueryOptions(shopPk, id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 상품 카테고리로 상품 목록 조회
 * @summary 상품 카테고리로 상품 목록 조회
 */
export const shopsProductCategoriesProductsList = (
  shopPk: number,
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<AdminProductRes[]>(
    { url: `/admin/shops/${shopPk}/product-categories/${id}/products/`, method: 'get', signal },
    options
  )
}

export const getShopsProductCategoriesProductsListQueryKey = (shopPk: number, id: number) =>
  [`/admin/shops/${shopPk}/product-categories/${id}/products/`] as const

export const getShopsProductCategoriesProductsListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof shopsProductCategoriesProductsList>>,
  TError = ErrorType<unknown>
>(
  shopPk: number,
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof shopsProductCategoriesProductsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryOptions<Awaited<ReturnType<typeof shopsProductCategoriesProductsList>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getShopsProductCategoriesProductsListQueryKey(shopPk, id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof shopsProductCategoriesProductsList>>> = ({ signal }) =>
    shopsProductCategoriesProductsList(shopPk, id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!(shopPk && id), ...queryOptions }
}

export type ShopsProductCategoriesProductsListInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof shopsProductCategoriesProductsList>>
>
export type ShopsProductCategoriesProductsListInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 상품 카테고리로 상품 목록 조회
 */
export const useShopsProductCategoriesProductsListInfinite = <
  TData = Awaited<ReturnType<typeof shopsProductCategoriesProductsList>>,
  TError = ErrorType<unknown>
>(
  shopPk: number,
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof shopsProductCategoriesProductsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getShopsProductCategoriesProductsListInfiniteQueryOptions(shopPk, id, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getShopsProductCategoriesProductsListQueryOptions = <
  TData = Awaited<ReturnType<typeof shopsProductCategoriesProductsList>>,
  TError = ErrorType<unknown>
>(
  shopPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof shopsProductCategoriesProductsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof shopsProductCategoriesProductsList>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getShopsProductCategoriesProductsListQueryKey(shopPk, id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof shopsProductCategoriesProductsList>>> = ({ signal }) =>
    shopsProductCategoriesProductsList(shopPk, id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!(shopPk && id), ...queryOptions }
}

export type ShopsProductCategoriesProductsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof shopsProductCategoriesProductsList>>
>
export type ShopsProductCategoriesProductsListQueryError = ErrorType<unknown>

/**
 * @summary 상품 카테고리로 상품 목록 조회
 */
export const useShopsProductCategoriesProductsList = <
  TData = Awaited<ReturnType<typeof shopsProductCategoriesProductsList>>,
  TError = ErrorType<unknown>
>(
  shopPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof shopsProductCategoriesProductsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getShopsProductCategoriesProductsListQueryOptions(shopPk, id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 상품 카테고리로 상품 목록 수정
 * @summary 상품 카테고리로 상품 목록 수정
 */
export const shopsProductCategoriesProductsBulkUpdatePartialUpdate = (
  shopPk: number,
  id: number,
  patchedAdminProductBulkUpdateReqRequest: BodyType<PatchedAdminProductBulkUpdateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<AdminProductRes[]>(
    {
      url: `/admin/shops/${shopPk}/product-categories/${id}/products/bulk-update/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: patchedAdminProductBulkUpdateReqRequest
    },
    options
  )
}

export const getShopsProductCategoriesProductsBulkUpdatePartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsProductCategoriesProductsBulkUpdatePartialUpdate>>,
    TError,
    { shopPk: number; id: number; data: BodyType<PatchedAdminProductBulkUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof shopsProductCategoriesProductsBulkUpdatePartialUpdate>>,
  TError,
  { shopPk: number; id: number; data: BodyType<PatchedAdminProductBulkUpdateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof shopsProductCategoriesProductsBulkUpdatePartialUpdate>>,
    { shopPk: number; id: number; data: BodyType<PatchedAdminProductBulkUpdateReqRequest> }
  > = (props) => {
    const { shopPk, id, data } = props ?? {}

    return shopsProductCategoriesProductsBulkUpdatePartialUpdate(shopPk, id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ShopsProductCategoriesProductsBulkUpdatePartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof shopsProductCategoriesProductsBulkUpdatePartialUpdate>>
>
export type ShopsProductCategoriesProductsBulkUpdatePartialUpdateMutationBody =
  BodyType<PatchedAdminProductBulkUpdateReqRequest>
export type ShopsProductCategoriesProductsBulkUpdatePartialUpdateMutationError = ErrorType<unknown>

/**
 * @summary 상품 카테고리로 상품 목록 수정
 */
export const useShopsProductCategoriesProductsBulkUpdatePartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsProductCategoriesProductsBulkUpdatePartialUpdate>>,
    TError,
    { shopPk: number; id: number; data: BodyType<PatchedAdminProductBulkUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getShopsProductCategoriesProductsBulkUpdatePartialUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 상품 카테고리 일괄 수정
 * @summary 상품 카테고리 일괄 수정
 */
export const shopsProductCategoriesBulkUpdatePartialUpdate = (
  shopPk: number,
  patchedAdminProductCategoryBulkUpdateReqRequest: BodyType<PatchedAdminProductCategoryBulkUpdateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<AdminProductCategoryRes[]>(
    {
      url: `/admin/shops/${shopPk}/product-categories/bulk-update/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: patchedAdminProductCategoryBulkUpdateReqRequest
    },
    options
  )
}

export const getShopsProductCategoriesBulkUpdatePartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsProductCategoriesBulkUpdatePartialUpdate>>,
    TError,
    { shopPk: number; data: BodyType<PatchedAdminProductCategoryBulkUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof shopsProductCategoriesBulkUpdatePartialUpdate>>,
  TError,
  { shopPk: number; data: BodyType<PatchedAdminProductCategoryBulkUpdateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof shopsProductCategoriesBulkUpdatePartialUpdate>>,
    { shopPk: number; data: BodyType<PatchedAdminProductCategoryBulkUpdateReqRequest> }
  > = (props) => {
    const { shopPk, data } = props ?? {}

    return shopsProductCategoriesBulkUpdatePartialUpdate(shopPk, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ShopsProductCategoriesBulkUpdatePartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof shopsProductCategoriesBulkUpdatePartialUpdate>>
>
export type ShopsProductCategoriesBulkUpdatePartialUpdateMutationBody =
  BodyType<PatchedAdminProductCategoryBulkUpdateReqRequest>
export type ShopsProductCategoriesBulkUpdatePartialUpdateMutationError = ErrorType<unknown>

/**
 * @summary 상품 카테고리 일괄 수정
 */
export const useShopsProductCategoriesBulkUpdatePartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsProductCategoriesBulkUpdatePartialUpdate>>,
    TError,
    { shopPk: number; data: BodyType<PatchedAdminProductCategoryBulkUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getShopsProductCategoriesBulkUpdatePartialUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 상품 목록 조회
 * @summary 상품 목록 조회
 */
export const shopsProductsList = (
  shopPk: number,
  params?: ShopsProductsListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedAdminProductResList>(
    { url: `/admin/shops/${shopPk}/products/`, method: 'get', params, signal },
    options
  )
}

export const getShopsProductsListQueryKey = (shopPk: number, params?: ShopsProductsListParams) =>
  [`/admin/shops/${shopPk}/products/`, ...(params ? [params] : [])] as const

export const getShopsProductsListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof shopsProductsList>>,
  TError = ErrorType<unknown>
>(
  shopPk: number,
  params?: ShopsProductsListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof shopsProductsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryOptions<Awaited<ReturnType<typeof shopsProductsList>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getShopsProductsListQueryKey(shopPk, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof shopsProductsList>>> = ({ signal }) =>
    shopsProductsList(shopPk, params, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!shopPk, ...queryOptions }
}

export type ShopsProductsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof shopsProductsList>>>
export type ShopsProductsListInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 상품 목록 조회
 */
export const useShopsProductsListInfinite = <
  TData = Awaited<ReturnType<typeof shopsProductsList>>,
  TError = ErrorType<unknown>
>(
  shopPk: number,
  params?: ShopsProductsListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof shopsProductsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getShopsProductsListInfiniteQueryOptions(shopPk, params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getShopsProductsListQueryOptions = <
  TData = Awaited<ReturnType<typeof shopsProductsList>>,
  TError = ErrorType<unknown>
>(
  shopPk: number,
  params?: ShopsProductsListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof shopsProductsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof shopsProductsList>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getShopsProductsListQueryKey(shopPk, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof shopsProductsList>>> = ({ signal }) =>
    shopsProductsList(shopPk, params, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!shopPk, ...queryOptions }
}

export type ShopsProductsListQueryResult = NonNullable<Awaited<ReturnType<typeof shopsProductsList>>>
export type ShopsProductsListQueryError = ErrorType<unknown>

/**
 * @summary 상품 목록 조회
 */
export const useShopsProductsList = <
  TData = Awaited<ReturnType<typeof shopsProductsList>>,
  TError = ErrorType<unknown>
>(
  shopPk: number,
  params?: ShopsProductsListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof shopsProductsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getShopsProductsListQueryOptions(shopPk, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 상품 추가
 * @summary 상품 추가
 */
export const shopsProductsCreate = (
  shopPk: number,
  adminProductCreateReqRequest: BodyType<AdminProductCreateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  const formData = customFormData(adminProductCreateReqRequest)
  return customInstance<AdminProductRes>(
    {
      url: `/admin/shops/${shopPk}/products/`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData
    },
    options
  )
}

export const getShopsProductsCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsProductsCreate>>,
    TError,
    { shopPk: number; data: BodyType<AdminProductCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof shopsProductsCreate>>,
  TError,
  { shopPk: number; data: BodyType<AdminProductCreateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof shopsProductsCreate>>,
    { shopPk: number; data: BodyType<AdminProductCreateReqRequest> }
  > = (props) => {
    const { shopPk, data } = props ?? {}

    return shopsProductsCreate(shopPk, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ShopsProductsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof shopsProductsCreate>>>
export type ShopsProductsCreateMutationBody = BodyType<AdminProductCreateReqRequest>
export type ShopsProductsCreateMutationError = ErrorType<unknown>

/**
 * @summary 상품 추가
 */
export const useShopsProductsCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsProductsCreate>>,
    TError,
    { shopPk: number; data: BodyType<AdminProductCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getShopsProductsCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 상품 상세 조회
 * @summary 상품 상세 조회
 */
export const shopsProductsRetrieve = (
  shopPk: number,
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<AdminProductRes>(
    { url: `/admin/shops/${shopPk}/products/${id}/`, method: 'get', signal },
    options
  )
}

export const getShopsProductsRetrieveQueryKey = (shopPk: number, id: number) =>
  [`/admin/shops/${shopPk}/products/${id}/`] as const

export const getShopsProductsRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof shopsProductsRetrieve>>,
  TError = ErrorType<unknown>
>(
  shopPk: number,
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof shopsProductsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryOptions<Awaited<ReturnType<typeof shopsProductsRetrieve>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getShopsProductsRetrieveQueryKey(shopPk, id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof shopsProductsRetrieve>>> = ({ signal }) =>
    shopsProductsRetrieve(shopPk, id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!(shopPk && id), ...queryOptions }
}

export type ShopsProductsRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof shopsProductsRetrieve>>>
export type ShopsProductsRetrieveInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 상품 상세 조회
 */
export const useShopsProductsRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof shopsProductsRetrieve>>,
  TError = ErrorType<unknown>
>(
  shopPk: number,
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof shopsProductsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getShopsProductsRetrieveInfiniteQueryOptions(shopPk, id, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getShopsProductsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof shopsProductsRetrieve>>,
  TError = ErrorType<unknown>
>(
  shopPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof shopsProductsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof shopsProductsRetrieve>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getShopsProductsRetrieveQueryKey(shopPk, id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof shopsProductsRetrieve>>> = ({ signal }) =>
    shopsProductsRetrieve(shopPk, id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!(shopPk && id), ...queryOptions }
}

export type ShopsProductsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof shopsProductsRetrieve>>>
export type ShopsProductsRetrieveQueryError = ErrorType<unknown>

/**
 * @summary 상품 상세 조회
 */
export const useShopsProductsRetrieve = <
  TData = Awaited<ReturnType<typeof shopsProductsRetrieve>>,
  TError = ErrorType<unknown>
>(
  shopPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof shopsProductsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getShopsProductsRetrieveQueryOptions(shopPk, id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 상품 부분 수정
 * @summary 상품 부분 수정
 */
export const shopsProductsPartialUpdate = (
  shopPk: number,
  id: number,
  patchedAdminProductPartialUpdateReqRequest: BodyType<PatchedAdminProductPartialUpdateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  const formData = customFormData(patchedAdminProductPartialUpdateReqRequest)
  return customInstance<AdminProductRes>(
    {
      url: `/admin/shops/${shopPk}/products/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData
    },
    options
  )
}

export const getShopsProductsPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsProductsPartialUpdate>>,
    TError,
    { shopPk: number; id: number; data: BodyType<PatchedAdminProductPartialUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof shopsProductsPartialUpdate>>,
  TError,
  { shopPk: number; id: number; data: BodyType<PatchedAdminProductPartialUpdateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof shopsProductsPartialUpdate>>,
    { shopPk: number; id: number; data: BodyType<PatchedAdminProductPartialUpdateReqRequest> }
  > = (props) => {
    const { shopPk, id, data } = props ?? {}

    return shopsProductsPartialUpdate(shopPk, id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ShopsProductsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof shopsProductsPartialUpdate>>
>
export type ShopsProductsPartialUpdateMutationBody = BodyType<PatchedAdminProductPartialUpdateReqRequest>
export type ShopsProductsPartialUpdateMutationError = ErrorType<unknown>

/**
 * @summary 상품 부분 수정
 */
export const useShopsProductsPartialUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsProductsPartialUpdate>>,
    TError,
    { shopPk: number; id: number; data: BodyType<PatchedAdminProductPartialUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getShopsProductsPartialUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 상품 삭제: 삭제됨 상태로 처리
 * @summary 상품 삭제
 */
export const shopsProductsDestroy = (shopPk: number, id: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/admin/shops/${shopPk}/products/${id}/`, method: 'delete' }, options)
}

export const getShopsProductsDestroyMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsProductsDestroy>>,
    TError,
    { shopPk: number; id: number },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof shopsProductsDestroy>>,
  TError,
  { shopPk: number; id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof shopsProductsDestroy>>,
    { shopPk: number; id: number }
  > = (props) => {
    const { shopPk, id } = props ?? {}

    return shopsProductsDestroy(shopPk, id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ShopsProductsDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof shopsProductsDestroy>>>

export type ShopsProductsDestroyMutationError = ErrorType<unknown>

/**
 * @summary 상품 삭제
 */
export const useShopsProductsDestroy = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsProductsDestroy>>,
    TError,
    { shopPk: number; id: number },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getShopsProductsDestroyMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 대표 상품 지정/해제
 * @summary 대표 상품 지정/해제
 */
export const shopsProductsMainUpdate = (
  shopPk: number,
  id: number,
  adminProductUpdateMainReqRequest: BodyType<AdminProductUpdateMainReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<AdminProductRes>(
    {
      url: `/admin/shops/${shopPk}/products/${id}/main/`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: adminProductUpdateMainReqRequest
    },
    options
  )
}

export const getShopsProductsMainUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsProductsMainUpdate>>,
    TError,
    { shopPk: number; id: number; data: BodyType<AdminProductUpdateMainReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof shopsProductsMainUpdate>>,
  TError,
  { shopPk: number; id: number; data: BodyType<AdminProductUpdateMainReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof shopsProductsMainUpdate>>,
    { shopPk: number; id: number; data: BodyType<AdminProductUpdateMainReqRequest> }
  > = (props) => {
    const { shopPk, id, data } = props ?? {}

    return shopsProductsMainUpdate(shopPk, id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ShopsProductsMainUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof shopsProductsMainUpdate>>>
export type ShopsProductsMainUpdateMutationBody = BodyType<AdminProductUpdateMainReqRequest>
export type ShopsProductsMainUpdateMutationError = ErrorType<unknown>

/**
 * @summary 대표 상품 지정/해제
 */
export const useShopsProductsMainUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsProductsMainUpdate>>,
    TError,
    { shopPk: number; id: number; data: BodyType<AdminProductUpdateMainReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getShopsProductsMainUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 대표 이미지 등록
 * @summary 대표 이미지 등록
 */
export const shopsProductsMainImageUpdate = (
  shopPk: number,
  id: number,
  adminProductUpdateImageReqRequest: BodyType<AdminProductUpdateImageReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  const formData = customFormData(adminProductUpdateImageReqRequest)
  return customInstance<AdminProductRes>(
    {
      url: `/admin/shops/${shopPk}/products/${id}/main-image/`,
      method: 'put',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData
    },
    options
  )
}

export const getShopsProductsMainImageUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsProductsMainImageUpdate>>,
    TError,
    { shopPk: number; id: number; data: BodyType<AdminProductUpdateImageReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof shopsProductsMainImageUpdate>>,
  TError,
  { shopPk: number; id: number; data: BodyType<AdminProductUpdateImageReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof shopsProductsMainImageUpdate>>,
    { shopPk: number; id: number; data: BodyType<AdminProductUpdateImageReqRequest> }
  > = (props) => {
    const { shopPk, id, data } = props ?? {}

    return shopsProductsMainImageUpdate(shopPk, id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ShopsProductsMainImageUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof shopsProductsMainImageUpdate>>
>
export type ShopsProductsMainImageUpdateMutationBody = BodyType<AdminProductUpdateImageReqRequest>
export type ShopsProductsMainImageUpdateMutationError = ErrorType<unknown>

/**
 * @summary 대표 이미지 등록
 */
export const useShopsProductsMainImageUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsProductsMainImageUpdate>>,
    TError,
    { shopPk: number; id: number; data: BodyType<AdminProductUpdateImageReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getShopsProductsMainImageUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 상품 판매 상태 지정
 * @summary 상품 판매 상태 지정
 */
export const shopsProductsSaleStatusUpdate = (
  shopPk: number,
  id: number,
  adminProductUpdateSaleStatusReqRequest: BodyType<AdminProductUpdateSaleStatusReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<AdminProductRes>(
    {
      url: `/admin/shops/${shopPk}/products/${id}/sale-status/`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: adminProductUpdateSaleStatusReqRequest
    },
    options
  )
}

export const getShopsProductsSaleStatusUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsProductsSaleStatusUpdate>>,
    TError,
    { shopPk: number; id: number; data: BodyType<AdminProductUpdateSaleStatusReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof shopsProductsSaleStatusUpdate>>,
  TError,
  { shopPk: number; id: number; data: BodyType<AdminProductUpdateSaleStatusReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof shopsProductsSaleStatusUpdate>>,
    { shopPk: number; id: number; data: BodyType<AdminProductUpdateSaleStatusReqRequest> }
  > = (props) => {
    const { shopPk, id, data } = props ?? {}

    return shopsProductsSaleStatusUpdate(shopPk, id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ShopsProductsSaleStatusUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof shopsProductsSaleStatusUpdate>>
>
export type ShopsProductsSaleStatusUpdateMutationBody = BodyType<AdminProductUpdateSaleStatusReqRequest>
export type ShopsProductsSaleStatusUpdateMutationError = ErrorType<unknown>

/**
 * @summary 상품 판매 상태 지정
 */
export const useShopsProductsSaleStatusUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsProductsSaleStatusUpdate>>,
    TError,
    { shopPk: number; id: number; data: BodyType<AdminProductUpdateSaleStatusReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getShopsProductsSaleStatusUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 상품 옵션 그룹 목록 조회
 * @summary 상품 옵션 그룹 목록 조회
 */
export const shopsProductsProductOptionGroupsList = (
  shopPk: number,
  productPk: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<AdminProductOptionGroupRes[]>(
    { url: `/admin/shops/${shopPk}/products/${productPk}/product-option-groups/`, method: 'get', signal },
    options
  )
}

export const getShopsProductsProductOptionGroupsListQueryKey = (shopPk: number, productPk: number) =>
  [`/admin/shops/${shopPk}/products/${productPk}/product-option-groups/`] as const

export const getShopsProductsProductOptionGroupsListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof shopsProductsProductOptionGroupsList>>,
  TError = ErrorType<unknown>
>(
  shopPk: number,
  productPk: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof shopsProductsProductOptionGroupsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryOptions<Awaited<ReturnType<typeof shopsProductsProductOptionGroupsList>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getShopsProductsProductOptionGroupsListQueryKey(shopPk, productPk)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof shopsProductsProductOptionGroupsList>>> = ({ signal }) =>
    shopsProductsProductOptionGroupsList(shopPk, productPk, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!(shopPk && productPk), ...queryOptions }
}

export type ShopsProductsProductOptionGroupsListInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof shopsProductsProductOptionGroupsList>>
>
export type ShopsProductsProductOptionGroupsListInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 상품 옵션 그룹 목록 조회
 */
export const useShopsProductsProductOptionGroupsListInfinite = <
  TData = Awaited<ReturnType<typeof shopsProductsProductOptionGroupsList>>,
  TError = ErrorType<unknown>
>(
  shopPk: number,
  productPk: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof shopsProductsProductOptionGroupsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getShopsProductsProductOptionGroupsListInfiniteQueryOptions(shopPk, productPk, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getShopsProductsProductOptionGroupsListQueryOptions = <
  TData = Awaited<ReturnType<typeof shopsProductsProductOptionGroupsList>>,
  TError = ErrorType<unknown>
>(
  shopPk: number,
  productPk: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof shopsProductsProductOptionGroupsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof shopsProductsProductOptionGroupsList>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getShopsProductsProductOptionGroupsListQueryKey(shopPk, productPk)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof shopsProductsProductOptionGroupsList>>> = ({ signal }) =>
    shopsProductsProductOptionGroupsList(shopPk, productPk, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!(shopPk && productPk), ...queryOptions }
}

export type ShopsProductsProductOptionGroupsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof shopsProductsProductOptionGroupsList>>
>
export type ShopsProductsProductOptionGroupsListQueryError = ErrorType<unknown>

/**
 * @summary 상품 옵션 그룹 목록 조회
 */
export const useShopsProductsProductOptionGroupsList = <
  TData = Awaited<ReturnType<typeof shopsProductsProductOptionGroupsList>>,
  TError = ErrorType<unknown>
>(
  shopPk: number,
  productPk: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof shopsProductsProductOptionGroupsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getShopsProductsProductOptionGroupsListQueryOptions(shopPk, productPk, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 상품 옵션 그룹 추가
 * @summary 상품 옵션 그룹 추가
 */
export const shopsProductsProductOptionGroupsCreate = (
  shopPk: number,
  productPk: number,
  adminProductOptionGroupCreateReqRequest: BodyType<AdminProductOptionGroupCreateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<AdminProductOptionGroupRes>(
    {
      url: `/admin/shops/${shopPk}/products/${productPk}/product-option-groups/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: adminProductOptionGroupCreateReqRequest
    },
    options
  )
}

export const getShopsProductsProductOptionGroupsCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsProductsProductOptionGroupsCreate>>,
    TError,
    { shopPk: number; productPk: number; data: BodyType<AdminProductOptionGroupCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof shopsProductsProductOptionGroupsCreate>>,
  TError,
  { shopPk: number; productPk: number; data: BodyType<AdminProductOptionGroupCreateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof shopsProductsProductOptionGroupsCreate>>,
    { shopPk: number; productPk: number; data: BodyType<AdminProductOptionGroupCreateReqRequest> }
  > = (props) => {
    const { shopPk, productPk, data } = props ?? {}

    return shopsProductsProductOptionGroupsCreate(shopPk, productPk, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ShopsProductsProductOptionGroupsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof shopsProductsProductOptionGroupsCreate>>
>
export type ShopsProductsProductOptionGroupsCreateMutationBody = BodyType<AdminProductOptionGroupCreateReqRequest>
export type ShopsProductsProductOptionGroupsCreateMutationError = ErrorType<unknown>

/**
 * @summary 상품 옵션 그룹 추가
 */
export const useShopsProductsProductOptionGroupsCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsProductsProductOptionGroupsCreate>>,
    TError,
    { shopPk: number; productPk: number; data: BodyType<AdminProductOptionGroupCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getShopsProductsProductOptionGroupsCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 상품 옵션 그룹 상세 조회
 * @summary 상품 옵션 그룹 상세 조회
 */
export const shopsProductsProductOptionGroupsRetrieve = (
  shopPk: number,
  productPk: number,
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<AdminProductOptionGroupRes>(
    { url: `/admin/shops/${shopPk}/products/${productPk}/product-option-groups/${id}/`, method: 'get', signal },
    options
  )
}

export const getShopsProductsProductOptionGroupsRetrieveQueryKey = (shopPk: number, productPk: number, id: number) =>
  [`/admin/shops/${shopPk}/products/${productPk}/product-option-groups/${id}/`] as const

export const getShopsProductsProductOptionGroupsRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof shopsProductsProductOptionGroupsRetrieve>>,
  TError = ErrorType<unknown>
>(
  shopPk: number,
  productPk: number,
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof shopsProductsProductOptionGroupsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryOptions<Awaited<ReturnType<typeof shopsProductsProductOptionGroupsRetrieve>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getShopsProductsProductOptionGroupsRetrieveQueryKey(shopPk, productPk, id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof shopsProductsProductOptionGroupsRetrieve>>> = ({ signal }) =>
    shopsProductsProductOptionGroupsRetrieve(shopPk, productPk, id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!(shopPk && productPk && id), ...queryOptions }
}

export type ShopsProductsProductOptionGroupsRetrieveInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof shopsProductsProductOptionGroupsRetrieve>>
>
export type ShopsProductsProductOptionGroupsRetrieveInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 상품 옵션 그룹 상세 조회
 */
export const useShopsProductsProductOptionGroupsRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof shopsProductsProductOptionGroupsRetrieve>>,
  TError = ErrorType<unknown>
>(
  shopPk: number,
  productPk: number,
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof shopsProductsProductOptionGroupsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getShopsProductsProductOptionGroupsRetrieveInfiniteQueryOptions(shopPk, productPk, id, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getShopsProductsProductOptionGroupsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof shopsProductsProductOptionGroupsRetrieve>>,
  TError = ErrorType<unknown>
>(
  shopPk: number,
  productPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof shopsProductsProductOptionGroupsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof shopsProductsProductOptionGroupsRetrieve>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getShopsProductsProductOptionGroupsRetrieveQueryKey(shopPk, productPk, id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof shopsProductsProductOptionGroupsRetrieve>>> = ({ signal }) =>
    shopsProductsProductOptionGroupsRetrieve(shopPk, productPk, id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!(shopPk && productPk && id), ...queryOptions }
}

export type ShopsProductsProductOptionGroupsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof shopsProductsProductOptionGroupsRetrieve>>
>
export type ShopsProductsProductOptionGroupsRetrieveQueryError = ErrorType<unknown>

/**
 * @summary 상품 옵션 그룹 상세 조회
 */
export const useShopsProductsProductOptionGroupsRetrieve = <
  TData = Awaited<ReturnType<typeof shopsProductsProductOptionGroupsRetrieve>>,
  TError = ErrorType<unknown>
>(
  shopPk: number,
  productPk: number,
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof shopsProductsProductOptionGroupsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getShopsProductsProductOptionGroupsRetrieveQueryOptions(shopPk, productPk, id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 상품 옵션 그룹 부분 수정
 * @summary 상품 옵션 그룹 부분 수정
 */
export const shopsProductsProductOptionGroupsPartialUpdate = (
  shopPk: number,
  productPk: number,
  id: number,
  patchedAdminProductOptionGroupPartialUpdateReqRequest: BodyType<PatchedAdminProductOptionGroupPartialUpdateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<AdminProductOptionGroupRes>(
    {
      url: `/admin/shops/${shopPk}/products/${productPk}/product-option-groups/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: patchedAdminProductOptionGroupPartialUpdateReqRequest
    },
    options
  )
}

export const getShopsProductsProductOptionGroupsPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsProductsProductOptionGroupsPartialUpdate>>,
    TError,
    {
      shopPk: number
      productPk: number
      id: number
      data: BodyType<PatchedAdminProductOptionGroupPartialUpdateReqRequest>
    },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof shopsProductsProductOptionGroupsPartialUpdate>>,
  TError,
  {
    shopPk: number
    productPk: number
    id: number
    data: BodyType<PatchedAdminProductOptionGroupPartialUpdateReqRequest>
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof shopsProductsProductOptionGroupsPartialUpdate>>,
    {
      shopPk: number
      productPk: number
      id: number
      data: BodyType<PatchedAdminProductOptionGroupPartialUpdateReqRequest>
    }
  > = (props) => {
    const { shopPk, productPk, id, data } = props ?? {}

    return shopsProductsProductOptionGroupsPartialUpdate(shopPk, productPk, id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ShopsProductsProductOptionGroupsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof shopsProductsProductOptionGroupsPartialUpdate>>
>
export type ShopsProductsProductOptionGroupsPartialUpdateMutationBody =
  BodyType<PatchedAdminProductOptionGroupPartialUpdateReqRequest>
export type ShopsProductsProductOptionGroupsPartialUpdateMutationError = ErrorType<unknown>

/**
 * @summary 상품 옵션 그룹 부분 수정
 */
export const useShopsProductsProductOptionGroupsPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsProductsProductOptionGroupsPartialUpdate>>,
    TError,
    {
      shopPk: number
      productPk: number
      id: number
      data: BodyType<PatchedAdminProductOptionGroupPartialUpdateReqRequest>
    },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getShopsProductsProductOptionGroupsPartialUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 상품 옵션 그룹 삭제
 * @summary 상품 옵션 그룹 삭제
 */
export const shopsProductsProductOptionGroupsDestroy = (
  shopPk: number,
  productPk: number,
  id: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    { url: `/admin/shops/${shopPk}/products/${productPk}/product-option-groups/${id}/`, method: 'delete' },
    options
  )
}

export const getShopsProductsProductOptionGroupsDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsProductsProductOptionGroupsDestroy>>,
    TError,
    { shopPk: number; productPk: number; id: number },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof shopsProductsProductOptionGroupsDestroy>>,
  TError,
  { shopPk: number; productPk: number; id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof shopsProductsProductOptionGroupsDestroy>>,
    { shopPk: number; productPk: number; id: number }
  > = (props) => {
    const { shopPk, productPk, id } = props ?? {}

    return shopsProductsProductOptionGroupsDestroy(shopPk, productPk, id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ShopsProductsProductOptionGroupsDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof shopsProductsProductOptionGroupsDestroy>>
>

export type ShopsProductsProductOptionGroupsDestroyMutationError = ErrorType<unknown>

/**
 * @summary 상품 옵션 그룹 삭제
 */
export const useShopsProductsProductOptionGroupsDestroy = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsProductsProductOptionGroupsDestroy>>,
    TError,
    { shopPk: number; productPk: number; id: number },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getShopsProductsProductOptionGroupsDestroyMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 상품 옵션 그룹의 상품 옵션 일괄 수정
 * @summary 상품 옵션 그룹의 상품 옵션 일괄 수정
 */
export const shopsProductsProductOptionGroupsProductOptionsBulkUpdatePartialUpdate = (
  shopPk: number,
  productPk: number,
  id: number,
  patchedAdminProductOptionBulkUpdateReqRequest: BodyType<PatchedAdminProductOptionBulkUpdateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<AdminProductOptionGroupRes>(
    {
      url: `/admin/shops/${shopPk}/products/${productPk}/product-option-groups/${id}/product-options/bulk-update/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: patchedAdminProductOptionBulkUpdateReqRequest
    },
    options
  )
}

export const getShopsProductsProductOptionGroupsProductOptionsBulkUpdatePartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsProductsProductOptionGroupsProductOptionsBulkUpdatePartialUpdate>>,
    TError,
    { shopPk: number; productPk: number; id: number; data: BodyType<PatchedAdminProductOptionBulkUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof shopsProductsProductOptionGroupsProductOptionsBulkUpdatePartialUpdate>>,
  TError,
  { shopPk: number; productPk: number; id: number; data: BodyType<PatchedAdminProductOptionBulkUpdateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof shopsProductsProductOptionGroupsProductOptionsBulkUpdatePartialUpdate>>,
    { shopPk: number; productPk: number; id: number; data: BodyType<PatchedAdminProductOptionBulkUpdateReqRequest> }
  > = (props) => {
    const { shopPk, productPk, id, data } = props ?? {}

    return shopsProductsProductOptionGroupsProductOptionsBulkUpdatePartialUpdate(
      shopPk,
      productPk,
      id,
      data,
      requestOptions
    )
  }

  return { mutationFn, ...mutationOptions }
}

export type ShopsProductsProductOptionGroupsProductOptionsBulkUpdatePartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof shopsProductsProductOptionGroupsProductOptionsBulkUpdatePartialUpdate>>
>
export type ShopsProductsProductOptionGroupsProductOptionsBulkUpdatePartialUpdateMutationBody =
  BodyType<PatchedAdminProductOptionBulkUpdateReqRequest>
export type ShopsProductsProductOptionGroupsProductOptionsBulkUpdatePartialUpdateMutationError = ErrorType<unknown>

/**
 * @summary 상품 옵션 그룹의 상품 옵션 일괄 수정
 */
export const useShopsProductsProductOptionGroupsProductOptionsBulkUpdatePartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsProductsProductOptionGroupsProductOptionsBulkUpdatePartialUpdate>>,
    TError,
    { shopPk: number; productPk: number; id: number; data: BodyType<PatchedAdminProductOptionBulkUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions =
    getShopsProductsProductOptionGroupsProductOptionsBulkUpdatePartialUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 상품 옵션 그룹 일괄 수정
 * @summary 상품 옵션 그룹 일괄 수정
 */
export const shopsProductsProductOptionGroupsBulkUpdatePartialUpdate = (
  shopPk: number,
  productPk: number,
  patchedAdminProductOptionGroupBulkUpdateReqRequest: BodyType<PatchedAdminProductOptionGroupBulkUpdateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<AdminProductOptionGroupRes[]>(
    {
      url: `/admin/shops/${shopPk}/products/${productPk}/product-option-groups/bulk-update/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: patchedAdminProductOptionGroupBulkUpdateReqRequest
    },
    options
  )
}

export const getShopsProductsProductOptionGroupsBulkUpdatePartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsProductsProductOptionGroupsBulkUpdatePartialUpdate>>,
    TError,
    { shopPk: number; productPk: number; data: BodyType<PatchedAdminProductOptionGroupBulkUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof shopsProductsProductOptionGroupsBulkUpdatePartialUpdate>>,
  TError,
  { shopPk: number; productPk: number; data: BodyType<PatchedAdminProductOptionGroupBulkUpdateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof shopsProductsProductOptionGroupsBulkUpdatePartialUpdate>>,
    { shopPk: number; productPk: number; data: BodyType<PatchedAdminProductOptionGroupBulkUpdateReqRequest> }
  > = (props) => {
    const { shopPk, productPk, data } = props ?? {}

    return shopsProductsProductOptionGroupsBulkUpdatePartialUpdate(shopPk, productPk, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ShopsProductsProductOptionGroupsBulkUpdatePartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof shopsProductsProductOptionGroupsBulkUpdatePartialUpdate>>
>
export type ShopsProductsProductOptionGroupsBulkUpdatePartialUpdateMutationBody =
  BodyType<PatchedAdminProductOptionGroupBulkUpdateReqRequest>
export type ShopsProductsProductOptionGroupsBulkUpdatePartialUpdateMutationError = ErrorType<unknown>

/**
 * @summary 상품 옵션 그룹 일괄 수정
 */
export const useShopsProductsProductOptionGroupsBulkUpdatePartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsProductsProductOptionGroupsBulkUpdatePartialUpdate>>,
    TError,
    { shopPk: number; productPk: number; data: BodyType<PatchedAdminProductOptionGroupBulkUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getShopsProductsProductOptionGroupsBulkUpdatePartialUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 상품 옵션 판매 상태 변경
 * @summary 상품 옵션 판매 상태 변경
 */
export const shopsProductsProductOptionsSaleStatusUpdate = (
  shopPk: string,
  productPk: number,
  id: number,
  adminProductOptionUpdateSaleStatusReqSerializersRequest: BodyType<AdminProductOptionUpdateSaleStatusReqSerializersRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<AdminProductOptionRes>(
    {
      url: `/admin/shops/${shopPk}/products/${productPk}/product-options/${id}/sale-status/`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: adminProductOptionUpdateSaleStatusReqSerializersRequest
    },
    options
  )
}

export const getShopsProductsProductOptionsSaleStatusUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsProductsProductOptionsSaleStatusUpdate>>,
    TError,
    {
      shopPk: string
      productPk: number
      id: number
      data: BodyType<AdminProductOptionUpdateSaleStatusReqSerializersRequest>
    },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof shopsProductsProductOptionsSaleStatusUpdate>>,
  TError,
  {
    shopPk: string
    productPk: number
    id: number
    data: BodyType<AdminProductOptionUpdateSaleStatusReqSerializersRequest>
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof shopsProductsProductOptionsSaleStatusUpdate>>,
    {
      shopPk: string
      productPk: number
      id: number
      data: BodyType<AdminProductOptionUpdateSaleStatusReqSerializersRequest>
    }
  > = (props) => {
    const { shopPk, productPk, id, data } = props ?? {}

    return shopsProductsProductOptionsSaleStatusUpdate(shopPk, productPk, id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ShopsProductsProductOptionsSaleStatusUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof shopsProductsProductOptionsSaleStatusUpdate>>
>
export type ShopsProductsProductOptionsSaleStatusUpdateMutationBody =
  BodyType<AdminProductOptionUpdateSaleStatusReqSerializersRequest>
export type ShopsProductsProductOptionsSaleStatusUpdateMutationError = ErrorType<unknown>

/**
 * @summary 상품 옵션 판매 상태 변경
 */
export const useShopsProductsProductOptionsSaleStatusUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsProductsProductOptionsSaleStatusUpdate>>,
    TError,
    {
      shopPk: string
      productPk: number
      id: number
      data: BodyType<AdminProductOptionUpdateSaleStatusReqSerializersRequest>
    },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getShopsProductsProductOptionsSaleStatusUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 엑셀로 상품 등록
 * @summary 엑셀로 상품 등록
 */
export const shopsProductsExcelCreate = (
  shopPk: number,
  adminProductExcelReqRequest: BodyType<AdminProductExcelReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  const formData = customFormData(adminProductExcelReqRequest)
  return customInstance<void>(
    {
      url: `/admin/shops/${shopPk}/products/excel/`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData
    },
    options
  )
}

export const getShopsProductsExcelCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsProductsExcelCreate>>,
    TError,
    { shopPk: number; data: BodyType<AdminProductExcelReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof shopsProductsExcelCreate>>,
  TError,
  { shopPk: number; data: BodyType<AdminProductExcelReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof shopsProductsExcelCreate>>,
    { shopPk: number; data: BodyType<AdminProductExcelReqRequest> }
  > = (props) => {
    const { shopPk, data } = props ?? {}

    return shopsProductsExcelCreate(shopPk, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ShopsProductsExcelCreateMutationResult = NonNullable<Awaited<ReturnType<typeof shopsProductsExcelCreate>>>
export type ShopsProductsExcelCreateMutationBody = BodyType<AdminProductExcelReqRequest>
export type ShopsProductsExcelCreateMutationError = ErrorType<unknown>

/**
 * @summary 엑셀로 상품 등록
 */
export const useShopsProductsExcelCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof shopsProductsExcelCreate>>,
    TError,
    { shopPk: number; data: BodyType<AdminProductExcelReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getShopsProductsExcelCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 어드민 사이트 목록 조회
 * @summary 어드민 사이트 목록 조회
 */
export const sitesList = (
  params?: SitesListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedAdminSiteResList>({ url: `/admin/sites/`, method: 'get', params, signal }, options)
}

export const getSitesListQueryKey = (params?: SitesListParams) =>
  [`/admin/sites/`, ...(params ? [params] : [])] as const

export const getSitesListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof sitesList>>,
  TError = ErrorType<unknown>
>(
  params?: SitesListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof sitesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryOptions<Awaited<ReturnType<typeof sitesList>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesList>>> = ({ signal }) =>
    sitesList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type SitesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof sitesList>>>
export type SitesListInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 어드민 사이트 목록 조회
 */
export const useSitesListInfinite = <TData = Awaited<ReturnType<typeof sitesList>>, TError = ErrorType<unknown>>(
  params?: SitesListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof sitesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSitesListInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getSitesListQueryOptions = <TData = Awaited<ReturnType<typeof sitesList>>, TError = ErrorType<unknown>>(
  params?: SitesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof sitesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof sitesList>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesList>>> = ({ signal }) =>
    sitesList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type SitesListQueryResult = NonNullable<Awaited<ReturnType<typeof sitesList>>>
export type SitesListQueryError = ErrorType<unknown>

/**
 * @summary 어드민 사이트 목록 조회
 */
export const useSitesList = <TData = Awaited<ReturnType<typeof sitesList>>, TError = ErrorType<unknown>>(
  params?: SitesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof sitesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSitesListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 어드민 로봇 목록 조회
 * @summary 어드민 로봇 목록 조회
 */
export const sitesRobotsList = (id: number, options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<AdminSiteRobotRes[]>({ url: `/admin/sites/${id}/robots/`, method: 'get', signal }, options)
}

export const getSitesRobotsListQueryKey = (id: number) => [`/admin/sites/${id}/robots/`] as const

export const getSitesRobotsListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof sitesRobotsList>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof sitesRobotsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryOptions<Awaited<ReturnType<typeof sitesRobotsList>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesRobotsListQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesRobotsList>>> = ({ signal }) =>
    sitesRobotsList(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type SitesRobotsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof sitesRobotsList>>>
export type SitesRobotsListInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 어드민 로봇 목록 조회
 */
export const useSitesRobotsListInfinite = <
  TData = Awaited<ReturnType<typeof sitesRobotsList>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof sitesRobotsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSitesRobotsListInfiniteQueryOptions(id, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getSitesRobotsListQueryOptions = <
  TData = Awaited<ReturnType<typeof sitesRobotsList>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof sitesRobotsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof sitesRobotsList>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesRobotsListQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesRobotsList>>> = ({ signal }) =>
    sitesRobotsList(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type SitesRobotsListQueryResult = NonNullable<Awaited<ReturnType<typeof sitesRobotsList>>>
export type SitesRobotsListQueryError = ErrorType<unknown>

/**
 * @summary 어드민 로봇 목록 조회
 */
export const useSitesRobotsList = <TData = Awaited<ReturnType<typeof sitesRobotsList>>, TError = ErrorType<unknown>>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof sitesRobotsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSitesRobotsListQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 어드민 토큰 갱신
 * @summary 어드민 토큰 갱신
 */
export const tokenRefreshCreate = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<AdminTokenRefreshRes>({ url: `/admin/token/refresh/`, method: 'post' }, options)
}

export const getTokenRefreshCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof tokenRefreshCreate>>, TError, TVariables, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof tokenRefreshCreate>>, TError, TVariables, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof tokenRefreshCreate>>, TVariables> = () => {
    return tokenRefreshCreate(requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type TokenRefreshCreateMutationResult = NonNullable<Awaited<ReturnType<typeof tokenRefreshCreate>>>

export type TokenRefreshCreateMutationError = ErrorType<unknown>

/**
 * @summary 어드민 토큰 갱신
 */
export const useTokenRefreshCreate = <TError = ErrorType<unknown>, TVariables = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof tokenRefreshCreate>>, TError, TVariables, TContext>
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getTokenRefreshCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 사용자 목록 조회
 * @summary 사용자 목록 조회
 */
export const usersList = (
  params?: UsersListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedUserResList>({ url: `/admin/users/`, method: 'get', params, signal }, options)
}

export const getUsersListQueryKey = (params?: UsersListParams) =>
  [`/admin/users/`, ...(params ? [params] : [])] as const

export const getUsersListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof usersList>>,
  TError = ErrorType<unknown>
>(
  params?: UsersListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof usersList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryOptions<Awaited<ReturnType<typeof usersList>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUsersListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersList>>> = ({ signal }) =>
    usersList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type UsersListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof usersList>>>
export type UsersListInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 사용자 목록 조회
 */
export const useUsersListInfinite = <TData = Awaited<ReturnType<typeof usersList>>, TError = ErrorType<unknown>>(
  params?: UsersListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof usersList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUsersListInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getUsersListQueryOptions = <TData = Awaited<ReturnType<typeof usersList>>, TError = ErrorType<unknown>>(
  params?: UsersListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof usersList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof usersList>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUsersListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersList>>> = ({ signal }) =>
    usersList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type UsersListQueryResult = NonNullable<Awaited<ReturnType<typeof usersList>>>
export type UsersListQueryError = ErrorType<unknown>

/**
 * @summary 사용자 목록 조회
 */
export const useUsersList = <TData = Awaited<ReturnType<typeof usersList>>, TError = ErrorType<unknown>>(
  params?: UsersListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof usersList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUsersListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 관리자 회원 생성
 * @summary 관리자 회원 생성
 */
export const usersCreate = (
  adminUserReqRequest: BodyType<AdminUserReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<UserRes>(
    {
      url: `/admin/users/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: adminUserReqRequest
    },
    options
  )
}

export const getUsersCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersCreate>>,
    TError,
    { data: BodyType<AdminUserReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof usersCreate>>,
  TError,
  { data: BodyType<AdminUserReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof usersCreate>>,
    { data: BodyType<AdminUserReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return usersCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type UsersCreateMutationResult = NonNullable<Awaited<ReturnType<typeof usersCreate>>>
export type UsersCreateMutationBody = BodyType<AdminUserReqRequest>
export type UsersCreateMutationError = ErrorType<unknown>

/**
 * @summary 관리자 회원 생성
 */
export const useUsersCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersCreate>>,
    TError,
    { data: BodyType<AdminUserReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getUsersCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 유저 삭제
 * @summary 유저 삭제
 */
export const usersCascadeDestroy = (id: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<string>({ url: `/admin/users/${id}/cascade/`, method: 'delete' }, options)
}

export const getUsersCascadeDestroyMutationOptions = <
  TError = ErrorType<UserDelete404Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof usersCascadeDestroy>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof usersCascadeDestroy>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersCascadeDestroy>>, { id: number }> = (props) => {
    const { id } = props ?? {}

    return usersCascadeDestroy(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type UsersCascadeDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof usersCascadeDestroy>>>

export type UsersCascadeDestroyMutationError = ErrorType<UserDelete404Exception>

/**
 * @summary 유저 삭제
 */
export const useUsersCascadeDestroy = <TError = ErrorType<UserDelete404Exception>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof usersCascadeDestroy>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getUsersCascadeDestroyMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 비밀번호 초기화
 * @summary 비밀번호 초기화
 */
export const usersResetPasswordPartialUpdate = (
  id: number,
  patchedAdminUserResetPasswordReqRequest: BodyType<PatchedAdminUserResetPasswordReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<UserRes>(
    {
      url: `/admin/users/${id}/reset-password/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: patchedAdminUserResetPasswordReqRequest
    },
    options
  )
}

export const getUsersResetPasswordPartialUpdateMutationOptions = <
  TError = ErrorType<UserResetPassword404Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersResetPasswordPartialUpdate>>,
    TError,
    { id: number; data: BodyType<PatchedAdminUserResetPasswordReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof usersResetPasswordPartialUpdate>>,
  TError,
  { id: number; data: BodyType<PatchedAdminUserResetPasswordReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof usersResetPasswordPartialUpdate>>,
    { id: number; data: BodyType<PatchedAdminUserResetPasswordReqRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return usersResetPasswordPartialUpdate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type UsersResetPasswordPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof usersResetPasswordPartialUpdate>>
>
export type UsersResetPasswordPartialUpdateMutationBody = BodyType<PatchedAdminUserResetPasswordReqRequest>
export type UsersResetPasswordPartialUpdateMutationError = ErrorType<UserResetPassword404Exception>

/**
 * @summary 비밀번호 초기화
 */
export const useUsersResetPasswordPartialUpdate = <
  TError = ErrorType<UserResetPassword404Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersResetPasswordPartialUpdate>>,
    TError,
    { id: number; data: BodyType<PatchedAdminUserResetPasswordReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getUsersResetPasswordPartialUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 관리자 정보 조회
 * @summary 관리자 정보 조회
 */
export const usersInfoRetrieve = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<UserInfoRes>({ url: `/admin/users/info/`, method: 'get', signal }, options)
}

export const getUsersInfoRetrieveQueryKey = () => [`/admin/users/info/`] as const

export const getUsersInfoRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof usersInfoRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof usersInfoRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseInfiniteQueryOptions<Awaited<ReturnType<typeof usersInfoRetrieve>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUsersInfoRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersInfoRetrieve>>> = ({ signal }) =>
    usersInfoRetrieve(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type UsersInfoRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof usersInfoRetrieve>>>
export type UsersInfoRetrieveInfiniteQueryError = ErrorType<unknown>

/**
 * @summary 관리자 정보 조회
 */
export const useUsersInfoRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof usersInfoRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof usersInfoRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUsersInfoRetrieveInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getUsersInfoRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof usersInfoRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof usersInfoRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseQueryOptions<Awaited<ReturnType<typeof usersInfoRetrieve>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUsersInfoRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersInfoRetrieve>>> = ({ signal }) =>
    usersInfoRetrieve(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type UsersInfoRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof usersInfoRetrieve>>>
export type UsersInfoRetrieveQueryError = ErrorType<unknown>

/**
 * @summary 관리자 정보 조회
 */
export const useUsersInfoRetrieve = <
  TData = Awaited<ReturnType<typeof usersInfoRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof usersInfoRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUsersInfoRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 유저 정보 확인
 * @summary 유저 정보 확인
 */
export const usersVerifyRetrieve = (
  params: UsersVerifyRetrieveParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<AdminUserVerifyRes>({ url: `/admin/users/verify/`, method: 'get', params, signal }, options)
}

export const getUsersVerifyRetrieveQueryKey = (params: UsersVerifyRetrieveParams) =>
  [`/admin/users/verify/`, ...(params ? [params] : [])] as const

export const getUsersVerifyRetrieveInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof usersVerifyRetrieve>>,
  TError = ErrorType<UserVerify404Exception>
>(
  params: UsersVerifyRetrieveParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof usersVerifyRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryOptions<Awaited<ReturnType<typeof usersVerifyRetrieve>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUsersVerifyRetrieveQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersVerifyRetrieve>>> = ({ signal }) =>
    usersVerifyRetrieve(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type UsersVerifyRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof usersVerifyRetrieve>>>
export type UsersVerifyRetrieveInfiniteQueryError = ErrorType<UserVerify404Exception>

/**
 * @summary 유저 정보 확인
 */
export const useUsersVerifyRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof usersVerifyRetrieve>>,
  TError = ErrorType<UserVerify404Exception>
>(
  params: UsersVerifyRetrieveParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof usersVerifyRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUsersVerifyRetrieveInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getUsersVerifyRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof usersVerifyRetrieve>>,
  TError = ErrorType<UserVerify404Exception>
>(
  params: UsersVerifyRetrieveParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof usersVerifyRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof usersVerifyRetrieve>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUsersVerifyRetrieveQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersVerifyRetrieve>>> = ({ signal }) =>
    usersVerifyRetrieve(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type UsersVerifyRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof usersVerifyRetrieve>>>
export type UsersVerifyRetrieveQueryError = ErrorType<UserVerify404Exception>

/**
 * @summary 유저 정보 확인
 */
export const useUsersVerifyRetrieve = <
  TData = Awaited<ReturnType<typeof usersVerifyRetrieve>>,
  TError = ErrorType<UserVerify404Exception>
>(
  params: UsersVerifyRetrieveParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof usersVerifyRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUsersVerifyRetrieveQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 바우처 활성화
 * @summary 바우처 활성화
 */
export const vouchersActivateCreate = (
  voucherActivateReqRequest: BodyType<VoucherActivateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<VoucherRes>(
    {
      url: `/admin/vouchers/activate/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: voucherActivateReqRequest
    },
    options
  )
}

export const getVouchersActivateCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof vouchersActivateCreate>>,
    TError,
    { data: BodyType<VoucherActivateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof vouchersActivateCreate>>,
  TError,
  { data: BodyType<VoucherActivateReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof vouchersActivateCreate>>,
    { data: BodyType<VoucherActivateReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return vouchersActivateCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type VouchersActivateCreateMutationResult = NonNullable<Awaited<ReturnType<typeof vouchersActivateCreate>>>
export type VouchersActivateCreateMutationBody = BodyType<VoucherActivateReqRequest>
export type VouchersActivateCreateMutationError = ErrorType<unknown>

/**
 * @summary 바우처 활성화
 */
export const useVouchersActivateCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof vouchersActivateCreate>>,
    TError,
    { data: BodyType<VoucherActivateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getVouchersActivateCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 엑셀로 바우처 등록

권한: 뉴빌리티 어드민
 * @summary 엑셀로 바우처 등록
 */
export const vouchersExcelCreate = (
  voucherExcelReqRequest: BodyType<VoucherExcelReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  const formData = customFormData(voucherExcelReqRequest)
  return customInstance<VoucherRes[]>(
    {
      url: `/admin/vouchers/excel/`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData
    },
    options
  )
}

export const getVouchersExcelCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof vouchersExcelCreate>>,
    TError,
    { data: BodyType<VoucherExcelReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof vouchersExcelCreate>>,
  TError,
  { data: BodyType<VoucherExcelReqRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof vouchersExcelCreate>>,
    { data: BodyType<VoucherExcelReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return vouchersExcelCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type VouchersExcelCreateMutationResult = NonNullable<Awaited<ReturnType<typeof vouchersExcelCreate>>>
export type VouchersExcelCreateMutationBody = BodyType<VoucherExcelReqRequest>
export type VouchersExcelCreateMutationError = ErrorType<unknown>

/**
 * @summary 엑셀로 바우처 등록
 */
export const useVouchersExcelCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof vouchersExcelCreate>>,
    TError,
    { data: BodyType<VoucherExcelReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const mutationOptions = getVouchersExcelCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
