/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

export type ShopsListOrderByItem = (typeof ShopsListOrderByItem)[keyof typeof ShopsListOrderByItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShopsListOrderByItem = {
  '-company': '-company',
  '-created_at': '-created_at',
  '-id': '-id',
  '-name': '-name',
  '-site': '-site',
  company: 'company',
  created_at: 'created_at',
  id: 'id',
  name: 'name',
  site: 'site'
} as const
