/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

/**
 * * `BASIC` - 자동 주문
 * `MANUAL` - 수동 주문
 */
export type OrderTypeEnum = (typeof OrderTypeEnum)[keyof typeof OrderTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderTypeEnum = {
  BASIC: 'BASIC',
  MANUAL: 'MANUAL'
} as const
