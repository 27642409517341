/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

export type OrdersListDeliveryStatusItem =
  (typeof OrdersListDeliveryStatusItem)[keyof typeof OrdersListDeliveryStatusItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrdersListDeliveryStatusItem = {
  ARRIVED: 'ARRIVED',
  CANCELED: 'CANCELED',
  DELIVERING: 'DELIVERING',
  DONE: 'DONE',
  MOVING: 'MOVING'
} as const
