/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

/**
 * * `ADMIN` - 어드민
 * `SHOP_ADMIN` - 상점 어드민
 * `SITE_ADMIN` - 사이트 어드민
 * `USER` - 일반 유저
 */
export type UserTypeEnum = (typeof UserTypeEnum)[keyof typeof UserTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserTypeEnum = {
  ADMIN: 'ADMIN',
  SHOP_ADMIN: 'SHOP_ADMIN',
  SITE_ADMIN: 'SITE_ADMIN',
  USER: 'USER'
} as const
