/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

/**
 * * `ARRIVED` - 뉴비 도착
 * `CANCELED` - 배달 취소
 * `DELIVERING` - 배달중
 * `DONE` - 배달완료
 * `MOVING` - 뉴비 이동중
 */
export type DeliveryStatusC50Enum = (typeof DeliveryStatusC50Enum)[keyof typeof DeliveryStatusC50Enum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeliveryStatusC50Enum = {
  ARRIVED: 'ARRIVED',
  CANCELED: 'CANCELED',
  DELIVERING: 'DELIVERING',
  DONE: 'DONE',
  MOVING: 'MOVING'
} as const
