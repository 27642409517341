/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

/**
 * * `CANCELED` - 취소
 * `DELIVERING` - 배송
 * `DONE` - 완료
 * `LOADING` - 적재
 */
export type AdminDeliveryResDeliveryStatusEnum =
  (typeof AdminDeliveryResDeliveryStatusEnum)[keyof typeof AdminDeliveryResDeliveryStatusEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdminDeliveryResDeliveryStatusEnum = {
  CANCELED: 'CANCELED',
  DELIVERING: 'DELIVERING',
  DONE: 'DONE',
  LOADING: 'LOADING'
} as const
