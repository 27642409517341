/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

export type OrdersListOrderByItem = (typeof OrdersListOrderByItem)[keyof typeof OrdersListOrderByItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrdersListOrderByItem = {
  '-id': '-id',
  id: 'id'
} as const
