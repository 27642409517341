/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

export type ManualOrdersListOrderStatusItem =
  (typeof ManualOrdersListOrderStatusItem)[keyof typeof ManualOrdersListOrderStatusItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ManualOrdersListOrderStatusItem = {
  CANCELED: 'CANCELED',
  CHECKING: 'CHECKING',
  DELIVERED: 'DELIVERED',
  DELIVERING: 'DELIVERING',
  ERROR: 'ERROR',
  PREPARING: 'PREPARING',
  READY: 'READY',
  REQUEST_CANCEL: 'REQUEST_CANCEL'
} as const
