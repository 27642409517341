/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

/**
 * * `IOS` - iOS
 * `ANDROID` - ANDROID
 */
export type DeviceTypeEnum = (typeof DeviceTypeEnum)[keyof typeof DeviceTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeviceTypeEnum = {
  IOS: 'IOS',
  ANDROID: 'ANDROID'
} as const
