/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

export type DetailedDeliveryStatusEnum = (typeof DetailedDeliveryStatusEnum)[keyof typeof DetailedDeliveryStatusEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DetailedDeliveryStatusEnum = {
  LOADING_START: 'LOADING_START',
  LOADING_ARRIVED: 'LOADING_ARRIVED',
  LOADING_COVER_OPEN: 'LOADING_COVER_OPEN',
  LOADING_COVER_CLOSED: 'LOADING_COVER_CLOSED',
  DELIVERING_START: 'DELIVERING_START',
  DELIVERING_ARRIVED: 'DELIVERING_ARRIVED',
  DELIVERING_COVER_OPEN: 'DELIVERING_COVER_OPEN',
  DELIVERING_COVER_CLOSED: 'DELIVERING_COVER_CLOSED',
  RETURNING_START: 'RETURNING_START',
  RETURNING_ARRIVED: 'RETURNING_ARRIVED',
  DONE: 'DONE',
  CANCELED: 'CANCELED'
} as const
