/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */

export type ShopsProductsListOrderByItem =
  (typeof ShopsProductsListOrderByItem)[keyof typeof ShopsProductsListOrderByItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShopsProductsListOrderByItem = {
  '-id': '-id',
  id: 'id'
} as const
